import { Component, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'

import { ResourceDefinition } from '../../../common/interfaces/resource-definition.interface'

import { ResourceService } from '../../../common/services/resource.service'
import { invoiceDefinition } from '../../invoice/invoice.definition'
import { Invoice } from '../../invoice/invoice.interface'
import { projectDefinition } from '../project.definition'
import { Project } from '../project.interface'

@Component({
  selector: 'app-project-accounting',
  templateUrl: './project-accounting.component.html',
  styleUrls: ['./project-accounting.component.scss']
})
export class ProjectAccountingComponent implements OnInit {
  @Input() project: Project
  @Input() invoiceToPayId: string

  invoices: Invoice[]
  invoiceToPay: Invoice

  projectDefinition: ResourceDefinition = projectDefinition
  invoiceDefinition: ResourceDefinition = invoiceDefinition

  projectRules: string
  redirectToAfterCreate: string

  totalToBill: number
  totalInvoicesAmount: number
  totalExpensesAmount: number
  totalCreditsAmount: number

  constructor(
    private resourceService: ResourceService,
    private router: Router
  ) {}

  ngOnInit() {
    this.projectRules = JSON.stringify([
      {
        fieldId: 'projectId',
        forcedValue: { projectId: this.project.id }
      }
    ])
    this.redirectToAfterCreate = JSON.stringify(
      `/${projectDefinition.path || projectDefinition.slug}/${
        this.project.id
      }?selectedTab=accounting`
    )

    this.resourceService
      .list('invoices', {
        projectIds: this.project.id,
        withoutPagination: true
      })
      .subscribe((invoiceRes: Invoice[]) => {
        this.invoices = invoiceRes
        this.totalInvoicesAmount = this.invoices.reduce(
          (sum, curr) => sum + curr.amount,
          0
        )

        if (this.invoiceToPayId) {
          this.invoiceToPay = this.invoices.find(
            (i) => i.id.toString() === this.invoiceToPayId
          )
        }
      })
  }

  reload() {
    this.router.navigate([`/projets/${this.project.id}`], {
      queryParams: {
        selectedTab: 'accounting',
        reload: new Date().toISOString()
      },
      queryParamsHandling: 'merge'
    })
  }

  onCustomEventHappen(event: { name: string; value: Invoice }) {
    if (event.name === 'promptDeletePayment') {
      this.invoiceToPay = event.value
    }
  }
}
