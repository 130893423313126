import { Gender } from '../../common/enums/gender.enum'
import { LinkType } from '../../common/enums/link-type.enum'
import { ResourceDefinition } from '../../common/interfaces/resource-definition.interface'

export const referentDefinition: ResourceDefinition = {
  title: 'Responsables',
  nameSingular: 'responsable',
  namePlural: 'responsables',
  gender: Gender.Masculine,
  path: 'responsables',
  slug: 'referents',
  hasDetailPage: false,
  hasListPage: true,
  buttons: [LinkType.CREATE],
  defaultLink: LinkType.EDIT,
  childrenThatPreventDelete: [
    {
      propName: 'projects',
      preventDeleteMessage: `Ce responsable est associé à un ou plusieurs projets`
    }
  ],
  dropdownLinks: [
    {
      type: LinkType.EDIT,
      permission: 'editReferents'
    },
    {
      type: LinkType.DELETE,
      permission: 'deleteReferents'
    }
  ],
  yields: [
    {
      label: 'Responsable',
      property: 'name'
    },
    {
      label: 'Client',
      property: 'customer.name'
    },
    {
      label: 'Mail',
      property: 'email'
    },
    {
      label: 'Tél',
      property: 'phone'
    }
  ]
}
