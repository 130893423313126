import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import { AbcListComponent } from '../../../common/base-components/abc-list/abc-list.component'
import { InputType } from '../../../common/enums/input-type.enum'
import { Filter } from '../../../common/interfaces/filter.interface'
import { ResourceDefinition } from '../../../common/interfaces/resource-definition.interface'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { FlashMessageService } from '../../../common/services/flash-message.service'
import { ResourceService } from '../../../common/services/resource.service'
import { User } from '../../user/user.interface'
import { customerDefinition } from '../customer.definition'

@Component({
  templateUrl:
    '../../../common/base-components/abc-list/abc-list.component.html',
  styleUrls: [
    '../../../common/base-components/abc-list/abc-list.component.scss'
  ]
})
export class CustomerListComponent extends AbcListComponent implements OnInit {
  definition: ResourceDefinition = customerDefinition

  filters: Filter[] = [
    {
      label: 'Rechercher un client',
      placeholder: 'Chercher par nom de client, account manager...',
      properties: {
        customerIds: 'customerIds',
        userIds: 'userIds'
      },
      searchResources: ['customers', 'users'],
      inputType: InputType.MultiSearch,
      className: 'is-6 p-x-0-mobile'
    }
  ]

  constructor(
    router: Router,
    activatedRoute: ActivatedRoute,
    resourceService: ResourceService,
    breadcrumbService: BreadcrumbService,
    flashMessageService: FlashMessageService
  ) {
    super(
      router,
      activatedRoute,
      breadcrumbService,
      resourceService,
      flashMessageService
    )
  }

  ngOnInit() {
    this.initListView()
  }
}
