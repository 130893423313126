import { Gender } from '../../common/enums/gender.enum'
import { LinkType } from '../../common/enums/link-type.enum'
import { YieldType } from '../../common/enums/yield-type.enum'
import { ResourceDefinition } from '../../common/interfaces/resource-definition.interface'

export const userDefinition: ResourceDefinition = {
  title: 'Collaborateurs',
  nameSingular: 'collaborateur',
  namePlural: 'collaborateurs',
  gender: Gender.Masculine,
  slug: 'users',
  path: 'collaborateurs',
  hasDetailPage: true,
  hasListPage: true,
  buttons: [LinkType.CREATE],
  defaultLink: LinkType.DETAIL,
  childrenThatPreventDelete: [
    {
      propName: 'projectCount',
      preventDeleteMessage: `Il est relié à un ou plusieurs projets.`
    },
    {
      propName: 'activityCount',
      preventDeleteMessage: `Des activités ont été enregistrées en son nom.`
    },
    {
      propName: 'customerAccountCount',
      preventDeleteMessage: `Il est relié à un ou plusieurs projets en tant que responsable client.`
    }
  ],
  dropdownLinks: [
    {
      type: LinkType.EDIT,
      permission: 'editUsers'
    },
    {
      type: LinkType.DELETE,
      permission: 'deleteUsers'
    }
  ],
  yields: [
    {
      label: 'Utilisateur',
      tooltip: `Nom de l'utilisateur`,
      property: 'imageObjects',
      secondProperty: 'name',
      orderByProperty: 'name',
      type: YieldType.Image
    },
    {
      label: 'Rôle',
      property: 'role.displayName'
    },
    {
      label: 'Profil',
      property: 'position.name'
    }
  ]
}
