import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import { AbcListComponent } from '../../../common/base-components/abc-list/abc-list.component'
import { InputType } from '../../../common/enums/input-type.enum'
import { Filter } from '../../../common/interfaces/filter.interface'
import { ResourceDefinition } from '../../../common/interfaces/resource-definition.interface'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { FlashMessageService } from '../../../common/services/flash-message.service'
import { ResourceService } from '../../../common/services/resource.service'
import { forecastedInvoiceDefinition } from '../forecasted-invoice.definition'

@Component({
  templateUrl:
    '../../../common/base-components/abc-list/abc-list.component.html',
  styleUrls: [
    '../../../common/base-components/abc-list/abc-list.component.scss'
  ]
})
export class ForecastedInvoiceListComponent
  extends AbcListComponent
  implements OnInit {
  definition: ResourceDefinition = forecastedInvoiceDefinition

  filters: Filter[] = [
    {
      label: 'Recherche de facture prévisionnelle',
      placeholder: 'Rechercher par client ou par projet...',

      properties: {
        projectIds: 'projectIds',
        customerIds: 'customerIds'
      },
      searchResources: ['projects', 'customers'],
      inputType: InputType.MultiSearch,
      className: 'is-12-touch is-6 p-x-0-mobile'
    },
    {
      label: `Date`,
      properties: {
        dateFrom: 'dateFrom',
        dateTo: 'dateTo'
      },
      inputType: InputType.DateRange,
      className: 'is-12-mobile is-12-tablet is-6-widescreen p-x-0-mobile '
    },
    {
      label: 'Factures récurrentes',
      properties: {
        value: 'recurrentOnly'
      },
      inputType: InputType.Checkbox,
      className: 'is-6 no-label p-x-0-mobile'
    },
    {
      label: 'Non facturées uniquement',
      properties: {
        value: 'withoutInvoiceOnly'
      },
      inputType: InputType.Checkbox,
      className: 'is-6 no-label p-x-0-mobile'
    }
  ]

  constructor(
    router: Router,
    activatedRoute: ActivatedRoute,
    resourceService: ResourceService,
    breadcrumbService: BreadcrumbService,
    flashMessageService: FlashMessageService
  ) {
    super(
      router,
      activatedRoute,
      breadcrumbService,
      resourceService,
      flashMessageService
    )
  }

  ngOnInit() {
    this.initListView()
  }
}
