import { Component } from '@angular/core'
import { IMyDate, IMyDateModel, IMyDpOptions } from 'mydatepicker'

@Component({
  template: 'NO UI TO BE FOUND HERE!'
})
export class DatepickerComponent {
  datePickerOptions: IMyDpOptions = {
    dateFormat: 'dd/mm/yyyy',
    height: '45px',
    selectorWidth: '310px',
    selectorHeight: 'auto',
    editableDateField: false,
    openSelectorOnInputClick: true
  }

  // Format YYYY-MM-DD to MyDatePicker format (today if date not specified)
  formatStandardDate(
    dateString = new Date().toISOString().substring(0, 10)
  ): { date: IMyDate } {
    // In case of NULL dateString (!! Different than empty), we return null to make datepicker empty
    if (!dateString) {
      return null
    }
    const spiltDateFrom = dateString.split('-')
    return {
      date: {
        year: parseInt(spiltDateFrom[0], 10),
        month: parseInt(spiltDateFrom[1], 10),
        day: parseInt(spiltDateFrom[2], 10)
      }
    }
  }

  // Format MyDatePicker format date to YYYY-MM-DD
  formatMyDatePickerDate(dateObject: IMyDateModel): string {
    return dateObject
      ? `${dateObject.date.year}-${dateObject.date.month
          .toString()
          .padStart(2, '0')}-${dateObject.date.day.toString().padStart(2, '0')}`
      : null
  }
}
