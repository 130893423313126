import { Component, HostListener, OnInit } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { Subscription } from 'rxjs'

import { appConstants } from './app.constants'
import { AuthService } from './common/services/auth.service'
import { EventService } from './common/services/event.service'
import { VersionService } from './common/services/version.service'
import { ViewportService } from './common/services/viewport.service'
import { User } from './resources/user/user.interface'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  isLogin: boolean
  isTouchResolution: boolean
  isCollapsed = false
  path: string

  private currentUser: User
  private eventSubscriptions = new Subscription()
  private subscription = new Subscription()

  constructor(
    private router: Router,
    private eventService: EventService,
    private authService: AuthService,
    private viewportService: ViewportService,
    private versionService: VersionService
  ) {
    this.eventSubscriptions.add(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.eventService.routeChanged.next({ url: event.url })
        }
      })
    )
  }

  ngOnInit() {
    this.authService.currentUser.subscribe((userRes: User) => {
      this.currentUser = userRes
    })

    this.viewportService.isTouchResolution.subscribe((newValue) => {
      this.isTouchResolution = newValue
    })

    this.setIsTouchResolution()

    this.subscription.add(
      this.eventService.routeChanged.subscribe((routeChanged) => {
        // Scroll top
        window.scrollTo(0, 0)

        this.path = routeChanged.url.includes('?')
          ? routeChanged.url.substring(0, routeChanged.url.indexOf('?'))
          : routeChanged.url
        this.isLogin =
          this.path.includes('/login') ||
          this.path.includes('forgot-password') ||
          this.path.includes('reset-password')

        if (
          !this.isLogin &&
          this.authService.isLoggedIn() &&
          !this.currentUser
        ) {
          this.getCurrentUser()
        }
      })
    )

    this.versionService.checkForNewVersions()
  }
  @HostListener('window:resize')
  onResize() {
    this.setIsTouchResolution()
  }

  setIsTouchResolution(): void {
    this.viewportService.isTouchResolution.next(
      window.innerWidth < appConstants.TOUCH_BREAKPOINT
    )
  }

  getCurrentUser(): void {
    this.authService.me().subscribe((userRes: User) => {
      this.authService.currentUser.next(userRes)
    })
  }
}
