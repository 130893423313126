import { Component, Input, OnInit } from '@angular/core'
import { CalendarEvent } from 'angular-calendar'
import * as moment from 'moment'

import { FieldSpecialRule } from '../../../common/interfaces/field-special-rule.interface'
import { PlannedWork } from '../../../common/interfaces/planned-work.interface'
import { FlashMessageService } from '../../../common/services/flash-message.service'
import { ResourceService } from '../../../common/services/resource.service'

@Component({
  selector: 'app-user-agenda',
  templateUrl: './user-agenda.component.html',
  styleUrls: ['./user-agenda.component.scss']
})
export class UserAgendaComponent implements OnInit {
  @Input() userId: number

  events: CalendarEvent[]
  plannedWorks: PlannedWork[]
  plannedWorkToEdit: PlannedWork
  plannedWorkToDelete: PlannedWork

  viewDate: Date = new Date()
  selectedDate: moment.Moment = moment()
  formRules: FieldSpecialRule[] = []
  holidayFormRules: FieldSpecialRule[] = []

  showCreateEditModal = false
  showCreateHolidayModal = false
  createEditMode: string

  holidayColors = {
    primary: '#ebcd34',
    secondary: '#4334eb'
  }

  constructor(
    private resourceService: ResourceService,
    private flashMessageService: FlashMessageService
  ) {}

  ngOnInit() {
    this.getPlannedWorks()

    this.holidayFormRules = [
      {
        fieldId: 'userId',
        forcedValue: {
          userId: this.userId
        },
        hidden: true
      }
    ]
  }

  getPlannedWorks() {
    const dateFrom: string = this.selectedDate
      .clone()
      .locale('fr')
      .startOf('month')
      .startOf('week')
      .format('YYYY-MM-DD')

    const dateTo: string = this.selectedDate
      .clone()
      .locale('fr')
      .endOf('month')
      .endOf('week')
      .format('YYYY-MM-DD')

    this.resourceService
      .list('planned-works', { userId: this.userId, dateFrom, dateTo })
      .subscribe((res: PlannedWork[]) => {
        this.events = this.formatPlannedWorksIntoCalendarEvents(res)
        this.plannedWorks = res
      })
  }

  formatPlannedWorksIntoCalendarEvents(
    plannedWorks: PlannedWork[]
  ): CalendarEvent[] {
    return plannedWorks.map((plannedWork: PlannedWork) => {
      const isHoliday = !plannedWork.project

      return {
        id: plannedWork.id,

        start: new Date(plannedWork.date),
        title: isHoliday ? 'Congés' : plannedWork.project.name,
        color: {
          primary: isHoliday
            ? this.holidayColors.primary
            : plannedWork.project.nature.color,
          secondary: isHoliday
            ? this.holidayColors.secondary
            : plannedWork.project.nature.color
        },
        meta: {
          daysOfWork: plannedWork.daysOfWork,
          projectId: plannedWork.project && plannedWork.project.id,
          date: plannedWork.date.substr(0, 10)
        }
      }
    })
  }

  goToMonth(event: Date) {
    this.selectedDate = moment(event)
    this.getPlannedWorks()
  }

  openCreateModal(event: { date: Date }) {
    this.formRules = [
      {
        fieldId: 'userId',
        forcedValue: {
          userId: this.userId
        },
        hidden: true
      },
      {
        fieldId: 'date',
        forcedValue: { value: moment(event.date).format('YYYY-MM-DD') },
        hidden: true
      }
    ]
    this.plannedWorkToEdit = null
    this.createEditMode = 'create'
    this.showCreateEditModal = true
  }

  openEditModal(clickEvent: MouseEvent, event: CalendarEvent): void {
    clickEvent.stopPropagation()
    this.formRules = [
      {
        fieldId: 'userId',
        forcedValue: {
          userId: this.userId
        },
        hidden: true
      },
      {
        fieldId: 'date',
        forcedValue: {
          value: event.meta.date
        },
        hidden: true
      }
    ]
    this.plannedWorkToEdit = this.plannedWorks.find((tS) => tS.id === event.id)
    this.createEditMode = 'edit'
    this.showCreateEditModal = true
  }

  deletePlannedWork(plannedWork: PlannedWork): void {
    this.resourceService
      .delete('planned-works', plannedWork.id)
      .subscribe((res) => {
        this.getPlannedWorks()
        this.flashMessageService.success('Le planning a bien été effacé.')
      })
  }

  promptDeletePlanning(clickEvent: MouseEvent, plannedWorkId: number) {
    clickEvent.stopPropagation()
    this.plannedWorkToDelete = this.plannedWorks.find(
      (tS) => tS.id === plannedWorkId
    )
  }
}
