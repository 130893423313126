import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import * as moment from 'moment'

import { AbcListComponent } from '../../common/base-components/abc-list/abc-list.component'
import { InputType } from '../../common/enums/input-type.enum'
import { Filter } from '../../common/interfaces/filter.interface'
import { ResourceDefinition } from '../../common/interfaces/resource-definition.interface'
import { BreadcrumbService } from '../../common/services/breadcrumb.service'
import { FlashMessageService } from '../../common/services/flash-message.service'
import { ResourceService } from '../../common/services/resource.service'
import { Nature } from '../../resources/nature/nature.interface'
import { productionAnalyticsDefinition } from './production-analytics-definition'

@Component({
  selector: 'app-production-analysis',
  templateUrl: './production-analysis.component.html',
  styleUrls: [
    '../../common/base-components/abc-create-edit/abc-create-edit.component.scss'
  ]
})
export class ProductionAnalysisComponent
  extends AbcListComponent
  implements OnInit {
  definition: ResourceDefinition = productionAnalyticsDefinition

  filters: Filter[] = [
    {
      label: 'Collaborateurs',
      placeholder: 'Chercher un ou plusieurs collaborateurs...',
      properties: {
        userIds: 'userIds'
      },
      searchResources: ['users'],
      inputType: InputType.MultiSearch,
      className: 'is-6-touch is-4 p-x-0-mobile'
    },
    {
      label: 'Nature',
      properties: {
        value: 'natureIds'
      },
      placeholder: 'Tous',
      selectOptions: () =>
        this.customResourceService
          .list('natures', { withoutPagination: true })
          .toPromise()
          .then((natureRes: Nature[]) =>
            natureRes.map((n) => ({
              label: n.name,
              value: n.id.toString()
            }))
          ),
      inputType: InputType.MultiSelect,
      className: 'is-6-mobile is-2-tablet is-2-widescreen p-x-0-mobile'
    },
    {
      label: `Dates`,
      properties: {
        dateFrom: 'dateFrom',
        dateTo: 'dateTo'
      },
      initialValue: {
        dateFrom: moment().startOf('month').format('YYYY-MM-DD'),
        dateTo: moment().endOf('month').format('YYYY-MM-DD')
      },
      inputType: InputType.DateRange,
      className: 'is-12-mobile is-12-tablet is-6-widescreen p-x-0-mobile '
    }
  ]

  constructor(
    router: Router,
    activatedRoute: ActivatedRoute,
    resourceService: ResourceService,
    breadcrumbService: BreadcrumbService,
    flashMessageService: FlashMessageService,
    private customResourceService: ResourceService
  ) {
    super(
      router,
      activatedRoute,
      breadcrumbService,
      resourceService,
      flashMessageService
    )
  }

  ngOnInit() {
    this.initListView()
  }
}
