import { Gender } from '../../common/enums/gender.enum'
import { LinkType } from '../../common/enums/link-type.enum'
import { YieldType } from '../../common/enums/yield-type.enum'
import { ResourceDefinition } from '../../common/interfaces/resource-definition.interface'

export const paymentDefinition: ResourceDefinition = {
  title: 'Paiements',
  nameSingular: 'paiement',
  namePlural: 'paiements',
  gender: Gender.Masculine,
  slug: 'payments',
  path: 'paiements',
  hasDetailPage: false,
  hasListPage: true,
  buttons: [LinkType.CREATE],
  defaultLink: LinkType.EDIT,
  dropdownLinks: [
    {
      type: LinkType.EDIT,
      permission: 'editPayments'
    },
    {
      type: LinkType.DELETE,
      permission: 'deletePayments'
    }
  ],
  yields: [
    {
      label: 'Date',
      property: 'date',
      type: YieldType.Date
    },
    {
      label: 'Facture',
      property: 'invoice.number'
    },
    {
      label: 'Projet',
      property: 'invoice.project.name'
    },
    {
      label: 'Client',
      property: 'invoice.project.customer.name'
    },
    {
      label: 'Montant (TTC)',
      property: 'amount',
      type: YieldType.Currency
    }
  ],
  keyNumbers: [
    {
      label: 'Total paiements (TTC)',
      className: 'label',
      extraParams: {
        calculateTotal: '1'
      }
    }
  ]
}
