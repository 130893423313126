import { Component, OnDestroy, OnInit } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'

import { AbcCreateEditComponent } from '../../../common/base-components/abc-create-edit/abc-create-edit.component'
import { InputType } from '../../../common/enums/input-type.enum'
import { Field } from '../../../common/interfaces/field.interface'
import { ResourceDefinition } from '../../../common/interfaces/resource-definition.interface'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { FlashMessageService } from '../../../common/services/flash-message.service'
import { ResourceService } from '../../../common/services/resource.service'
import { Position } from '../../position/position.interface'
import { Role } from '../../role/role.interface'
import { userDefinition } from '../user.definition'

@Component({
  templateUrl:
    '../../../common/base-components/abc-create-edit/abc-create-edit.component.html',
  styleUrls: [
    '../../../common/base-components/abc-create-edit/abc-create-edit.component.scss'
  ]
})
export class UserCreateEditComponent
  extends AbcCreateEditComponent
  implements OnInit, OnDestroy {
  definition: ResourceDefinition = userDefinition
  isEditMyself: boolean

  // EditMyself only : Changing user's own email makes token obsolete.
  emailChanged = false

  fields: Field[] = [
    {
      id: 'name',
      label: 'Nom',
      properties: {
        value: 'name'
      },
      className: 'p-x-0-mobile is-6',
      inputType: InputType.Text,
      validators: [Validators.required]
    },
    {
      id: 'roleId',
      label: `Rôle`,
      placeholder: `Choisir le rôle du collaborateur...`,
      properties: { value: 'roleId' },
      retrievedItemProperties: {
        roleId: 'role.id'
      },
      inputType: InputType.Select,
      selectOptions: () =>
        this.customResourceService
          .list('roles', { withoutPagination: true })
          .toPromise()
          .then((roleRes: Role[]) =>
            roleRes.map((r) => ({ label: r.name, value: r.id.toString() }))
          ),
      className: 'p-x-0-mobile is-6',
      validators: [Validators.required]
    },
    {
      id: 'email',
      label: 'Email',
      properties: {
        value: 'email'
      },
      className: 'p-x-0-mobile is-6',
      inputType: InputType.Email,
      validators: [Validators.required, Validators.email]
    },
    {
      id: 'positionId',
      label: `Profil`,
      placeholder: `Choisir le profil du collaborateur...`,
      properties: { value: 'positionId' },
      retrievedItemProperties: {
        positionId: 'position.id'
      },
      inputType: InputType.Select,
      selectOptions: () =>
        this.customResourceService
          .list('positions', { withoutPagination: true })
          .toPromise()
          .then((positionRes: Position[]) =>
            positionRes.map((p) => ({
              label: p.name,
              value: p.id.toString()
            }))
          ),
      className: 'p-x-0-mobile is-6',
      validators: [Validators.required]
    },
    {
      label: 'Mot de passe',
      properties: {
        value: 'password'
      },
      className: 'p-x-0-mobile is-6',
      inputType: InputType.Password,
      validators: [Validators.required],
      editValidators: []
    },
    {
      label: 'Avatar',
      placeholder: 'Choisir un fichier image',
      properties: { value: 'image' },
      className: 'p-x-0-mobile is-6',
      inputType: InputType.Image,
      validators: []
    },
    {
      label: 'Actif',
      helpText: `Seul les utilisateurs actifs peuvent se connecter à l'application`,
      properties: { value: 'isActive' },
      initialValue: { value: false },
      className: 'p-x-0-mobile is-6 aligned-checkbox',
      inputType: InputType.Checkbox,
      validators: []
    },
    {
      label: 'Couleur',
      properties: { value: 'color' },
      className: 'p-x-0-mobile is-6',
      inputType: InputType.ColorPicker,
      validators: [Validators.required]
    }
  ]

  constructor(
    formBuilder: FormBuilder,
    router: Router,
    breadcrumbService: BreadcrumbService,
    resourceService: ResourceService,
    flashMessageService: FlashMessageService,
    activatedRoute: ActivatedRoute,
    private customBreadcrumbService: BreadcrumbService,
    private customFlashMessageService: FlashMessageService,
    private customRouter: Router,
    private customActivatedRoute: ActivatedRoute,
    private customResourceService: ResourceService
  ) {
    super(
      formBuilder,
      router,
      breadcrumbService,
      resourceService,
      flashMessageService,
      activatedRoute
    )
    this.isEditMyself = activatedRoute.snapshot.data.editMyself
  }

  ngOnInit() {
    if (this.isEditMyself) {
      this.initUserEditMyselfView()
    } else {
      this.initCreateEditView()
    }
  }

  // Special version of this form when user edits hisself or herself.
  async initUserEditMyselfView() {
    this.mode = this.customActivatedRoute.snapshot.data.mode
    this.fieldSpecialRules = [
      {
        fieldId: 'roleId',
        hidden: true
      },
      {
        fieldId: 'positionId',
        hidden: true
      }
    ]

    this.resolvedFields = await this.resolveFields(this.fields)

    await this.getItem('myself')
    this.item.id = 'myself'

    this.form = this.generateForm(this.fields)

    this.form.valueChanges.subscribe((newValue: { email: string }) => {
      if (newValue.email !== this.item.email) {
        this.emailChanged = true
      }
    })

    this.customBreadcrumbService.breadcrumbLinks.next([
      {
        path: `/${this.definition.path || this.definition.slug}`,
        label: this.definition.title
      },
      {
        label: `Modifier mon profil`
      }
    ])
  }

  ngOnDestroy() {
    if (this.isEditMyself && this.emailChanged) {
      this.customRouter.navigate(['/logout'])
      this.customFlashMessageService.info(
        `Vous avez changé votre adresse email. Veuillez vous re-connecter avec votre nouvelle adresse.`
      )
    }
  }
}
