import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core'
import { Router } from '@angular/router'

import { LinkType } from '../../../../common/enums/link-type.enum'
import { YieldType } from '../../../../common/enums/yield-type.enum'
import { ActionButton } from '../../../../common/interfaces/action-button.interface'
import { DropdownLink } from '../../../../common/interfaces/dropdown-link.interface'
import { OrderByChangedEvent } from '../../../../common/interfaces/order-by-changed-event.interface'
import { ResourceDefinition } from '../../../../common/interfaces/resource-definition.interface'
import { Yield } from '../../../../common/interfaces/yield.interface'
import { AuthService } from '../../../../common/services/auth.service'
import { FlashMessageService } from '../../../../common/services/flash-message.service'
import { ResourceService } from '../../../../common/services/resource.service'

@Component({
  selector: 'abc-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnChanges {
  @Input() items: any[]
  @Input() definition: ResourceDefinition
  @Input() hiddenProps: string[] = []
  @Input() dropdownLinks: DropdownLink[]
  @Input() orderByDesc = false
  @Input() orderBy: string
  @Input() allowOrderBy = true

  @Output() customEventEmitter: EventEmitter<any> = new EventEmitter()
  @Output() reloadPrompted: EventEmitter<void> = new EventEmitter()
  @Output() orderByChanged: EventEmitter<OrderByChangedEvent> =
    new EventEmitter()

  yields: Yield[]

  formattedItems: { [key: string]: any }[]

  itemToDelete: any
  YieldType = YieldType

  constructor(
    private authService: AuthService,
    private resourceService: ResourceService,
    private flashMessageService: FlashMessageService,
    private router: Router
  ) {}

  async ngOnChanges() {
    const permissions = await this.authService.getPermissions()

    this.yields = this.hiddenProps.length
      ? this.definition.yields.filter(
          (y) =>
            !this.hiddenProps.find((hiddenProp) => hiddenProp === y.property)
        )
      : this.definition.yields

    this.yields.forEach((y: Yield) => {
      if (!this.allowOrderBy) {
        y.disableOrderBy = true
      }
    })

    this.items.forEach((item) => {
      if (this.definition.defaultLink === LinkType.EDIT) {
        item.link = [
          `/${this.definition.path || this.definition.slug}`,
          item.id.toString(),
          'edit'
        ]
      } else if (this.definition.defaultLink === LinkType.DETAIL) {
        item.link = [
          `/${this.definition.path || this.definition.slug}`,
          item.id.toString()
        ]
      } else {
        item.link = null
      }

      item.yields = []
      // Create object with values to be displayed.
      this.yields.forEach((y: Yield) => {
        const itemYield: Yield = { ...y }
        itemYield.propertyValue = this.getValue(item, itemYield.property)
        itemYield.secondPropertyValue = this.getValue(
          item,
          itemYield.secondProperty
        )
        itemYield.thirdPropertyValue = this.getValue(
          item,
          itemYield.thirdProperty
        )
        itemYield.forthPropertyValue = this.getValue(
          item,
          itemYield.forthProperty
        )
        item.yields.push(itemYield)
      })

      // Action buttons.
      item.actionButtons = []
      if (
        this.definition.actionButtons &&
        this.definition.actionButtons.length
      ) {
        this.definition.actionButtons.forEach((actionButton: ActionButton) => {
          if (
            permissions.includes(actionButton.permission) &&
            actionButton.condition(item)
          ) {
            item.actionButtons.push(actionButton)
          }
        })
      }

      // Check if item can be deleted.
      if (
        this.definition.childrenThatPreventDelete &&
        this.definition.childrenThatPreventDelete.length
      ) {
        this.definition.childrenThatPreventDelete.forEach(
          (children: { propName: string; preventDeleteMessage: string }) => {
            if (
              item[children.propName] &&
              (item[children.propName] > 0 || item[children.propName].length)
            ) {
              item.preventDeleteMessage = children.preventDeleteMessage
            }
          }
        )
      }
    })
    // We make the loop on formattedItems instead of items to prevent DOM from creating before finishing format operations.
    this.formattedItems = this.items
  }

  // Emit event on changing orderBy or orderByDesc.
  order(yieldName: Yield) {
    if (yieldName.disableOrderBy) {
      return
    }

    const property = yieldName.orderByProperty || yieldName.property

    if (this.orderBy === property) {
      this.orderByDesc = !this.orderByDesc
    }
    this.orderBy = property

    this.orderByChanged.emit({
      orderBy: this.orderBy,
      orderByDesc: this.orderByDesc
    })
  }

  // Recursive getter to retrieve nested properties.
  getValue(item: any, propName?: string): any {
    let value: any

    try {
      value = propName.split('.').reduce((prev, current) => prev[current], item)
    } catch (error) {
      value = null
    }
    return value
  }

  openConfirmDeleteModal(itemId: number): void {
    this.itemToDelete = this.items.find((i) => i.id === itemId)
  }

  triggerCustomAction(
    actionButton: ActionButton | DropdownLink,
    item: any
  ): void {
    const linkAction = actionButton.linkAction && actionButton.linkAction(item)
    const patchAction =
      actionButton.patchAction && actionButton.patchAction(item)

    if (linkAction) {
      this.router.navigate([linkAction.path], {
        queryParams: linkAction.queryParams
      })
    } else if (patchAction) {
      this.resourceService
        .patch(patchAction.resourceName, patchAction.id, patchAction.suffix)
        .subscribe(
          (res) => {
            this.flashMessageService.success(patchAction.successMessage)
            this.reloadPrompted.emit()
          },
          (err) => {
            this.flashMessageService.error(patchAction.errorMessage)
          }
        )
    }
  }
}
