import { Component, OnInit } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import * as moment from 'moment'

import { AbcCreateEditComponent } from '../../../common/base-components/abc-create-edit/abc-create-edit.component'
import { InputType } from '../../../common/enums/input-type.enum'
import { Field } from '../../../common/interfaces/field.interface'
import { ResourceDefinition } from '../../../common/interfaces/resource-definition.interface'
import { SelectOption } from '../../../common/interfaces/select-option.interface'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { FlashMessageService } from '../../../common/services/flash-message.service'
import { ResourceService } from '../../../common/services/resource.service'
import { Project } from '../../project/project.interface'
import { forecastedInvoiceDefinition } from '../forecasted-invoice.definition'

@Component({
  templateUrl:
    '../../../common/base-components/abc-create-edit/abc-create-edit.component.html',
  styleUrls: [
    '../../../common/base-components/abc-create-edit/abc-create-edit.component.scss'
  ]
})
export class ForecastedInvoiceCreateEditComponent
  extends AbcCreateEditComponent
  implements OnInit
{
  definition: ResourceDefinition = forecastedInvoiceDefinition

  fields: Field[] = [
    {
      id: 'projectId',
      label: 'Projet',
      placeholder: 'Projet',
      properties: {
        projectId: 'projectId'
      },
      retrievedItemProperties: {
        projectId: 'project.id'
      },
      searchResources: ['projects'],
      maxSelectedItems: 1,
      inputType: InputType.MultiSearch,
      validators: [Validators.required],
      className: 'is-12 p-x-0-mobile',
      onChange: async (newValue: { projectId: number }) => {
        const invoiceIdField: Field = this.getFieldById('invoiceId')

        if (newValue.projectId) {
          const project: Project = await this.customResourceService
            .show('projects', newValue.projectId)
            .toPromise()
            .then((res) => res)

          invoiceIdField.selectOptions = project.invoices.map((i) => ({
            label: `${i.number} ${i.reference ? '(' + i.reference + ')' : ''}`,
            value: i.id.toString()
          }))
        } else {
          invoiceIdField.selectOptions = []
        }
      }
    },
    {
      id: 'invoiceId',
      label: 'Facture reliée (optionnel)',
      placeholder: 'Aucune facture reliée',
      properties: {
        value: 'invoiceId'
      },
      selectOptions: () =>
        this.item && this.item.project
          ? this.customResourceService
              .listSelectOptions('invoices', {
                projectId: this.item.project.id
              })
              .then((selectOptionsRes: SelectOption[]) => selectOptionsRes)
          : Promise.resolve([]),
      retrievedItemProperties: {
        invoiceId: 'invoice.id'
      },
      inputType: InputType.Select,
      validators: [],
      className: 'is-3 p-x-0-mobile'
    },
    {
      label: 'Libellé de la prestation',
      properties: {
        value: 'reference'
      },
      inputType: InputType.Text,
      validators: [Validators.required],
      className: 'p-x-0-mobile is-6'
    },
    {
      label: `Date de facturation`,
      properties: {
        value: 'issueDate'
      },
      initialValue: {
        value: moment().format('YYYY-MM-DD')
      },
      inputType: InputType.Datepicker,
      validators: [Validators.required],
      className: 'p-x-0-mobile is-6'
    },
    {
      label: 'Probabilité de facturation (%)',
      placeholder: 'Entrez le pourcentage de facturation',
      properties: {
        value: 'issueProbability'
      },
      inputType: InputType.Number,
      min: 1,
      max: 100,
      validators: [Validators.required],
      className: 'p-x-0-mobile is-6'
    },
    {
      label: 'Montant HT',
      properties: {
        value: 'amount'
      },
      validators: [Validators.required],
      inputType: InputType.Number,
      className: 'p-x-0-mobile is-6',
      onChange: (newValue: { value: number }) => {
        const taxesField: Field = this.getFieldById('taxes')

        this.setFieldValue(taxesField, {
          value: Math.round(newValue.value * 0.2 * 100) / 100
        })
      }
    },
    {
      id: 'taxes',
      label: 'TVA',
      properties: {
        value: 'taxes'
      },
      validators: [Validators.required],
      inputType: InputType.Number,
      className: 'p-x-0-mobile is-6'
    },
    {
      label: `Date de récurrence (si applicable)`,
      properties: {
        value: 'recurrenceDate'
      },
      inputType: InputType.Datepicker,
      validators: [],
      className: 'p-x-0-mobile is-6'
    }
  ]

  constructor(
    formBuilder: FormBuilder,
    router: Router,
    breadcrumbService: BreadcrumbService,
    resourceService: ResourceService,
    flashMessageService: FlashMessageService,
    activatedRoute: ActivatedRoute,
    private customResourceService: ResourceService
  ) {
    super(
      formBuilder,
      router,
      breadcrumbService,
      resourceService,
      flashMessageService,
      activatedRoute
    )
  }

  ngOnInit() {
    this.initCreateEditView()
  }
}
