import { Gender } from '../../common/enums/gender.enum'
import { LinkType } from '../../common/enums/link-type.enum'
import { ResourceDefinition } from '../../common/interfaces/resource-definition.interface'

export const turnoverDefinition: ResourceDefinition = {
  title: `Chiffre d'affaires`,
  nameSingular: `Chiffre d'affaires`,
  namePlural: `Chiffre d'affaires`,
  hasDetailPage: false,
  hasListPage: true,
  gender: Gender.Masculine,
  slug: 'analytics/turnover',
  path: 'analytics/turnover',
  buttons: [],
  defaultLink: LinkType.DETAIL,
  childrenThatPreventDelete: [],
  actionButtons: [],
  dropdownLinks: [],
  yields: []
}
