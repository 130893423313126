import { Validators } from '@angular/forms'
import { Gender } from '../../common/enums/gender.enum'
import { InputType } from '../../common/enums/input-type.enum'
import { LinkType } from '../../common/enums/link-type.enum'
import { YieldType } from '../../common/enums/yield-type.enum'
import { ResourceDefinition } from '../../common/interfaces/resource-definition.interface'

export const natureDefinition: ResourceDefinition = {
  title: 'Natures',
  nameSingular: 'nature',
  namePlural: 'natures',
  gender: Gender.Feminine,
  slug: 'natures',
  hasDetailPage: false,
  hasListPage: true,
  buttons: [LinkType.CREATE],
  defaultLink: LinkType.EDIT,
  childrenThatPreventDelete: [
    {
      propName: 'projectCount',
      preventDeleteMessage: `Certains projets lui sont attribués et doivent d'abord être ré-affectés à une autre nature.`
    }
  ],
  dropdownLinks: [
    {
      type: LinkType.EDIT,
      permission: 'editNatures'
    },
    {
      type: LinkType.DELETE,
      permission: 'deleteNatures'
    }
  ],
  yields: [
    {
      label: '',
      property: 'color',
      type: YieldType.Color,
      width: 50
    },
    {
      label: 'Nature',
      property: 'name'
    }
  ]
}
