import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms'
import * as moment from 'moment'

import { InputType } from '../../../common/enums/input-type.enum'
import { FlashMessageService } from '../../../common/services/flash-message.service'
import { ResourceService } from '../../../common/services/resource.service'
import { Payment } from '../../payment/payment.interface'
import { Project } from '../../project/project.interface'
import { Invoice } from '../invoice.interface'

@Component({
  selector: 'app-invoice-payment-modal',
  templateUrl: './invoice-payment-modal.component.html',
  styleUrls: ['./invoice-payment-modal.component.scss']
})
export class InvoicePaymentModalComponent implements OnInit {
  @Input() invoice: Invoice
  @Input() project: Project
  @Output() modalClosed: EventEmitter<void> = new EventEmitter()
  @Output() paymentCreatedOrDeleted: EventEmitter<void> = new EventEmitter()

  remainingAmount: number

  form: FormGroup = this.formBuilder.group({
    date: [null, Validators.required],
    amount: [null, Validators.required]
  })

  InputType = InputType
  requiredValidators: ValidatorFn[] = [Validators.required]
  showErrors = false

  get formAmount() {
    return this.form.get('amount') as FormControl
  }

  constructor(
    private formBuilder: FormBuilder,
    private flashMessageService: FlashMessageService,
    private resourceService: ResourceService
  ) {}

  ngOnInit() {
    // Calculate remaining amount.
    this.remainingAmount =
      this.invoice.amount +
      this.invoice.taxes -
      this.invoice.payments.reduce(
        (sum: number, curr: Payment) => sum + curr.amount,
        0
      )

    // Set form suggested value.
    this.form.patchValue({
      date: moment().format('YYYY-MM-DD'),
      amount: this.remainingAmount
    })

    this.formAmount.setValidators([
      Validators.required,
      Validators.max(this.remainingAmount)
    ])
  }

  close() {
    this.modalClosed.emit()
  }

  async submit() {
    if (this.form.invalid) {
      this.showErrors = true
      return this.flashMessageService.error(
        `Impossible d'envoyer le formulaire: certains champs n'ont pas été remplis correctement.`
      )
    }

    this.resourceService
      .store(`invoices/${this.invoice.id}/payments`, this.form.value)
      .subscribe(
        (res) => {
          this.flashMessageService.success('Le paiement a bien été enregistré.')
          this.paymentCreatedOrDeleted.emit()
        },
        (err) => {
          this.flashMessageService.error(
            `Une erreur a eu lieu. Le paiement n'a pas pu être enregistré.`
          )
        }
      )
  }

  delete(id: number) {
    this.resourceService
      .delete(`invoices/${this.invoice.id}/payments`, id)
      .subscribe(
        (res) => {
          this.flashMessageService.success('Le paiement a bien été supprimé.')
          this.paymentCreatedOrDeleted.emit()
        },
        (err) => {
          this.flashMessageService.error(
            `Une erreur a eu lieu. Le paiement n'a pas pu être supprimé.`
          )
        }
      )
  }
}
