import { Gender } from '../../common/enums/gender.enum'
import { LinkType } from '../../common/enums/link-type.enum'
import { ResourceDefinition } from '../../common/interfaces/resource-definition.interface'

export const plannedWorkDefinition: ResourceDefinition = {
  title: 'Planning',
  nameSingular: 'planning',
  namePlural: 'plannings',
  gender: Gender.Masculine,
  slug: 'planned-works',
  hasDetailPage: false,
  hasListPage: true,
  buttons: [LinkType.CREATE],
  defaultLink: LinkType.EDIT,
  dropdownLinks: [],
  yields: []
}
