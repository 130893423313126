import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  HostListener
} from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { Router } from '@angular/router'

import { AbcCreateEditComponent } from '../../../common/base-components/abc-create-edit/abc-create-edit.component'
import { InputType } from '../../../common/enums/input-type.enum'
import { FieldSpecialRule } from '../../../common/interfaces/field-special-rule.interface'
import { Field } from '../../../common/interfaces/field.interface'
import { PlannedWork } from '../../../common/interfaces/planned-work.interface'
import { ResourceDefinition } from '../../../common/interfaces/resource-definition.interface'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { FlashMessageService } from '../../../common/services/flash-message.service'
import { ResourceService } from '../../../common/services/resource.service'
import { plannedWorkDefinition } from '../planned-work.definition'

@Component({
  selector: 'app-planned-work-create-edit-modal',
  templateUrl:
    '../../../common/base-components/abc-create-edit/abc-create-edit-modal.component.html',
  styleUrls: [
    '../../../common/base-components/abc-create-edit/abc-create-edit.component.scss'
  ]
})
export class PlannedWorkCreateEditModalComponent
  extends AbcCreateEditComponent
  implements OnInit {
  @Input() mode = 'create'
  @Input() fieldSpecialRules?: FieldSpecialRule[] = []
  @Input() item: PlannedWork

  @Output() closedModal: EventEmitter<void> = new EventEmitter()

  definition: ResourceDefinition = plannedWorkDefinition
  isModal = true

  fields: Field[] = [
    {
      id: 'userId',
      label: 'Collaborateur',
      placeholder: `Choisissez le collaborateur concerné...`,
      properties: {
        userId: 'userId'
      },
      retrievedItemProperties: {
        userId: 'user.id'
      },
      searchResources: ['users'],
      maxSelectedItems: 1,
      inputType: InputType.MultiSearch,
      validators: [Validators.required],
      className: 'is-12 p-x-0-mobile'
    },
    {
      id: 'projectId',
      label: 'Projet',
      placeholder: `Choisissez le projet...`,
      properties: {
        projectId: 'projectId'
      },
      retrievedItemProperties: {
        projectId: 'project.id'
      },
      searchResources: ['projects'],
      maxSelectedItems: 1,
      inputType: InputType.MultiSearch,
      validators: [Validators.required],
      className: 'is-12 p-x-0-mobile'
    },
    {
      id: 'date',
      label: `Date`,
      placeholder: `Date`,
      properties: {
        value: 'date'
      },
      inputType: InputType.Datepicker,
      validators: [Validators.required],
      className: 'is-12  p-x-0-mobile'
    },
    {
      id: 'daysOfWork',
      label: 'Durée en heures',
      placeholder: 'Indiquez la durée...',
      properties: {
        value: 'daysOfWork'
      },
      inputType: InputType.Radio,
      selectOptions: [
        {
          label: '2h',
          value: '0.25'
        },
        {
          label: '4h',
          value: '0.5'
        },
        {
          label: '6h',
          value: '0.75'
        },
        {
          label: '8h',
          value: '1'
        }
      ],
      validators: [Validators.required],
      className: 'is-12  p-x-0-mobile'
    }
  ]

  constructor(
    formBuilder: FormBuilder,
    router: Router,
    breadcrumbService: BreadcrumbService,
    resourceService: ResourceService,
    flashMessageService: FlashMessageService,
    private customResourceService: ResourceService
  ) {
    super(
      formBuilder,
      router,
      breadcrumbService,
      resourceService,
      flashMessageService
    )
  }

  ngOnInit() {
    this.initCreateEditView()
  }

  close() {
    this.closedModal.emit()
  }

  @HostListener('document:keydown.escape') onEnterKeydown() {
    this.close()
  }
}
