import { Injectable } from '@angular/core'
import { Title, Meta } from '@angular/platform-browser'
import { MetaObject } from '../interfaces/meta-object.interface'
import { environment } from '../../../environments/environment'
import { appConstants } from '../../app.constants'

@Injectable({
  providedIn: 'root'
})
export class MetaService {
  constructor(private title: Title, private meta: Meta) {}

  defaultMeta: MetaObject = {
    title: appConstants.APP_NAME,
    description: `Application de gestion d'activité`,
    path: '',
    ogImage: 'assets/images/logo.png'
  }

  setTags(metaObject: MetaObject): void {
    this.title.setTitle(
      `${metaObject.title || this.defaultMeta.title} | ${appConstants.APP_NAME}`
    )
    this.meta.addTags([
      {
        name: 'description',
        content: metaObject.description || this.defaultMeta.description
      },
      {
        name: 'og:description',
        content: metaObject.description || this.defaultMeta.description
      },
      {
        name: 'og:title',
        content: metaObject.title || this.defaultMeta.title
      },
      {
        name: 'og:url',
        content: environment.baseUrl + metaObject.path
      },
      {
        name: 'og:image',
        content:
          environment.baseUrl + (metaObject.ogImage || this.defaultMeta.ogImage)
      }
    ])
  }
}
