export enum YieldType {
  Text,
  Date,
  Address,
  Image,
  Currency,
  ProgressBar,
  Download,
  Color,
  FileIcon,
  Icon,
  AnalogProgressBar
}
