import { Gender } from '../../common/enums/gender.enum'
import { LinkType } from '../../common/enums/link-type.enum'
import { YieldType } from '../../common/enums/yield-type.enum'
import { ResourceDefinition } from '../../common/interfaces/resource-definition.interface'

export const productionAnalyticsDefinition: ResourceDefinition = {
  title: 'Analyse de la production',
  nameSingular: 'analyse de la production',
  namePlural: 'analyses de la production',
  hasDetailPage: false,
  hasListPage: true,
  gender: Gender.Feminine,
  slug: 'analytics/production-analysis',
  path: 'analytics/production-analysis',
  buttons: [],
  defaultLink: LinkType.DETAIL,
  childrenThatPreventDelete: [],
  actionButtons: [],
  dropdownLinks: [],
  yields: []
}
