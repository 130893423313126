import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import { AbcListComponent } from '../../../common/base-components/abc-list/abc-list.component'
import { InputType } from '../../../common/enums/input-type.enum'
import { Filter } from '../../../common/interfaces/filter.interface'
import { ResourceDefinition } from '../../../common/interfaces/resource-definition.interface'
import { AuthService } from '../../../common/services/auth.service'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { FlashMessageService } from '../../../common/services/flash-message.service'
import { ResourceService } from '../../../common/services/resource.service'
import { Nature } from '../../nature/nature.interface'
import { User } from '../../user/user.interface'
import { ProjectStatus } from '../project-status.enum'
import { projectDefinition } from '../project.definition'

@Component({
  templateUrl:
    '../../../common/base-components/abc-list/abc-list.component.html',
  styleUrls: [
    '../../../common/base-components/abc-list/abc-list.component.scss'
  ]
})
export class ProjectListComponent extends AbcListComponent implements OnInit {
  definition: ResourceDefinition = projectDefinition

  filters: Filter[] = [
    {
      label: 'Recherche de mission',
      placeholder: 'N° de mission, référence, client, responsable mission...',
      properties: {
        projectIds: 'projectIds',
        customerIds: 'customerIds',
        referentIds: 'referentIds'
      },
      searchResources: ['projects', 'customers', 'referents'],
      inputType: InputType.MultiSearch,
      className: 'is-12-touch is-6 p-x-0-mobile'
    },
    {
      label: 'Chef de Projet',
      properties: {
        value: 'projectManagerIds'
      },
      placeholder: 'Tous',
      selectOptions: () =>
        this.customResourceService
          .list('users', { withoutPagination: true })
          .toPromise()
          .then((userRes: User[]) =>
            userRes.map((u) => ({
              label: u.name,
              value: u.id.toString()
            }))
          ),
      inputType: InputType.MultiSelect,
      className: 'is-12-mobile is-6-tablet is-2-widescreen p-x-0-mobile'
    },
    {
      label: 'Nature',
      properties: {
        value: 'natureId'
      },
      placeholder: 'Tous',
      selectOptions: () =>
        this.customResourceService
          .list('natures', { withoutPagination: true })
          .toPromise()
          .then((natureRes: Nature[]) =>
            natureRes.map((n) => ({
              label: n.name,
              value: n.id.toString()
            }))
          ),
      inputType: InputType.Select,
      className: 'is-12-mobile is-6-tablet is-2-widescreen p-x-0-mobile'
    },
    {
      label: 'Statut',
      properties: {
        value: 'statuses'
      },
      selectOptions: Object.values(ProjectStatus).map((status) => ({
        label: status,
        value: status
      })),
      inputType: InputType.MultiSelect,
      className: 'is-12-mobile is-6-tablet  is-2-widescreen p-x-0-mobile'
    }
  ]

  constructor(
    router: Router,
    activatedRoute: ActivatedRoute,
    resourceService: ResourceService,
    breadcrumbService: BreadcrumbService,
    flashMessageService: FlashMessageService,
    private customResourceService: ResourceService,
    private authService: AuthService
  ) {
    super(
      router,
      activatedRoute,
      breadcrumbService,
      resourceService,
      flashMessageService
    )
  }

  async ngOnInit() {
    const canBrowseInvoices: boolean = await this.authService.can(
      'browseInvoices'
    )

    if (!canBrowseInvoices) {
      this.definition.yields = this.definition.yields.filter(
        (y) => y.property !== 'totalInvoicesAmount'
      )
    }
    this.initListView()
  }
}
