import { Gender } from '../../common/enums/gender.enum'
import { LinkType } from '../../common/enums/link-type.enum'
import { YieldType } from '../../common/enums/yield-type.enum'
import { ResourceDefinition } from '../../common/interfaces/resource-definition.interface'

export const customerDefinition: ResourceDefinition = {
  title: 'Clients',
  nameSingular: 'client',
  namePlural: 'clients',
  gender: Gender.Masculine,
  slug: 'customers',
  path: 'clients',
  hasDetailPage: false,
  hasListPage: true,
  buttons: [LinkType.CREATE],
  defaultLink: LinkType.DETAIL,
  childrenThatPreventDelete: [
    {
      propName: 'referentCount',
      preventDeleteMessage: `Il contient des responsables qui doivent être effacés avant.`
    },
    {
      propName: 'projectCount',
      preventDeleteMessage: `Certains projets lui sont attribués et doivent d'abord être effacés ou attribués à un autre client.`
    }
  ],
  dropdownLinks: [
    {
      type: LinkType.DETAIL,
      permission: 'readCustomers'
    },
    {
      type: LinkType.EDIT,
      permission: 'editCustomers'
    },
    {
      type: LinkType.DELETE,
      permission: 'deleteCustomers'
    }
  ],
  yields: [
    {
      label: 'Client',
      property: 'imageObjects',
      orderByProperty: 'name',
      secondProperty: 'name',
      type: YieldType.Image
    },
    {
      label: 'Adresse',
      property: 'address',
      type: YieldType.Address
    },
    {
      label: 'Email',
      property: 'billingEmail'
    },
    {
      label: 'Account Manager',
      property: 'accountManager.name'
    }
  ]
}
