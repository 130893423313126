import { Gender } from '../../common/enums/gender.enum'
import { LinkType } from '../../common/enums/link-type.enum'
import { YieldType } from '../../common/enums/yield-type.enum'
import { ResourceDefinition } from '../../common/interfaces/resource-definition.interface'

export const projectAnalyticsViewDefinition: ResourceDefinition = {
  title: 'Projets',
  nameSingular: 'projet',
  namePlural: 'projets',
  hasDetailPage: false,
  hasListPage: true,
  gender: Gender.Masculine,
  slug: 'projects',
  path: 'projets',
  buttons: [],
  defaultLink: LinkType.DETAIL,
  childrenThatPreventDelete: [],
  actionButtons: [],
  dropdownLinks: [],
  yields: [
    {
      label: 'Projet',
      property: 'nature.color',
      className: 'has-mini-circle',
      secondProperty: 'name',
      orderByProperty: 'name',
      type: YieldType.Color
    },
    {
      label: 'Jours de travail',
      property: 'plannedWorkDaysOfWork'
    },
    {
      label: 'Production estimée',
      property: 'plannedWorkValue',
      type: YieldType.Currency
    }
  ]
}
