import { Component, Input } from '@angular/core'

// * CASE Comment: First try of an "icon yield". It could be interesting to chose icon and allow the
// * possibility of passing an array of icons and showing tooltips on mouse hover.
@Component({
  selector: 'abc-icon-yield',
  template: `<i
    class="icon icon-bell is-size-5 has-text-success"
    *ngIf="showIcon"
  ></i>`
})
export class IconYieldComponent {
  @Input() showIcon: boolean
  @Input() iconClass: string
}
