import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { ResourceDefinition } from '../../../common/interfaces/resource-definition.interface'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { ResourceService } from '../../../common/services/resource.service'
import { projectDefinition } from '../../project/project.definition'
import { Project } from '../../project/project.interface'
import { Customer } from '../customer.interface'

@Component({
  selector: 'app-customer-detail',
  templateUrl: './customer-detail.component.html',
  styleUrls: ['./customer-detail.component.scss']
})
export class CustomerDetailComponent implements OnInit {
  customer: Customer
  projects: Project[]

  projectDefinition: ResourceDefinition = projectDefinition

  constructor(
    private activatedRoute: ActivatedRoute,
    private resourceService: ResourceService,
    private breadcrumbService: BreadcrumbService
  ) {
    // Add billingReferent yield.
    if (
      !this.projectDefinition.yields.find(
        (y) => y.property === 'billingReferent.name'
      )
    ) {
      this.projectDefinition.yields.splice(5, 0, {
        label: 'Res Facturation',
        property: 'billingReferent.name'
      })
    }
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: { id: string }) => {
      this.resourceService
        .show('customers', params.id)
        .subscribe((res: Customer) => {
          this.customer = res

          // Set Meta and Breadcrumbs.
          this.breadcrumbService.breadcrumbLinks.next([
            {
              label: 'Clients',
              path: '/clients'
            },
            {
              label: this.customer.name
            }
          ])
        })

      this.resourceService
        .list('projects', { customerIds: params.id, withoutPagination: true })
        .subscribe((projectRes: Project[]) => {
          this.projects = projectRes
        })
    })
  }
}
