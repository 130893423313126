import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { combineLatest } from 'rxjs'
import { map } from 'rxjs/operators'

import { ResourceDefinition } from '../../../common/interfaces/resource-definition.interface'

import { AuthService } from '../../../common/services/auth.service'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { MetaService } from '../../../common/services/meta.service'
import { ResourceService } from '../../../common/services/resource.service'
import { projectDefinition } from '../../project/project.definition'
import { Project } from '../../project/project.interface'
import { User } from '../user.interface'

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent implements OnInit {
  user: User
  projects: Project[]

  selectedTab: string
  isCurrentUser: boolean
  loadingProjects = false

  projectDefinition: ResourceDefinition = projectDefinition

  constructor(
    private activatedRoute: ActivatedRoute,
    private resourceService: ResourceService,
    private breadcrumbService: BreadcrumbService,
    private metaService: MetaService,
    private authService: AuthService,
    private router: Router
  ) {}

  async ngOnInit() {
    const canBrowseInvoices: boolean = await this.authService.can(
      'browseInvoices'
    )

    if (!canBrowseInvoices) {
      this.projectDefinition.yields = this.projectDefinition.yields.filter(
        (y) => y.property !== 'totalInvoicesAmount'
      )
    }

    combineLatest([
      this.activatedRoute.params,
      this.activatedRoute.queryParams,
      this.authService.currentUser
    ])
      .pipe(
        map((res) => ({
          params: res[0],
          queryParams: res[1],
          currentUser: res[2]
        }))
      )
      .subscribe(
        (res: {
          params: { id: string }
          queryParams: { selectedTab?: string }
          currentUser: User
        }) => {
          this.projects = null

          this.isCurrentUser =
            res.currentUser && res.params.id === res.currentUser.id.toString()

          this.selectedTab = res.queryParams.selectedTab || 'projects'

          this.resourceService
            .show('users', this.isCurrentUser ? 'myself' : res.params.id)
            .subscribe((userRes: User) => {
              this.user = userRes

              // Set Meta and Breadcrumbs.
              this.breadcrumbService.breadcrumbLinks.next([
                {
                  label: 'Collaborateurs',
                  path: '/collaborateurs'
                },
                {
                  label: this.user.name
                }
              ])
              this.metaService.setTags({
                title: this.user.name,
                path: `/collaborateurs/${this.user.id}`
              })
            })

          this.loadingProjects = true
          this.resourceService
            .list('projects', {
              projectManagerIds: res.params.id,
              withoutPagination: 'true',
              orderBy: 'status'
            })
            .subscribe((projectRes: Project[]) => {
              this.loadingProjects = false
              this.projects = projectRes
            })
        }
      )
  }

  reload() {
    this.router.navigate([this.activatedRoute.snapshot.url.join('/')], {
      queryParams: {
        reload: new Date().toISOString()
      },
      queryParamsHandling: 'merge'
    })
  }
}
