import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { AbcInput } from '../../../../common/interfaces/abc-input.interface'
import { ValidatorFn, FormGroup, FormBuilder, Validators } from '@angular/forms'
import { SelectOption } from '../../../../common/interfaces/select-option.interface'

@Component({
  selector: 'abc-radio-input',
  templateUrl: './radio-input.component.html',
  styleUrls: ['./radio-input.component.scss']
})
export class RadioInputComponent implements OnInit, AbcInput {
  @Input() label: string
  @Input() placeholder: string
  @Input() helpText: string
  @Input() initialValue: { value: string | number }
  @Input() selectOptions: SelectOption[]
  @Input() showErrors = false
  @Input() validators: ValidatorFn[] = []
  @Input() uniqueId: string

  @Output() valueChanged: EventEmitter<{
    value: string | number
  }> = new EventEmitter()

  required: boolean

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    if (this.initialValue && this.initialValue.value) {
      const initialValueString: string =
        typeof this.initialValue.value === 'number'
          ? this.initialValue.value.toString()
          : this.initialValue.value
      const initialSelectOption: SelectOption = this.selectOptions.find(
        (option) => option.value === initialValueString
      )

      if (initialSelectOption) {
        this.select(initialSelectOption)
      }
    }

    this.required = this.validators.includes(Validators.required)
  }

  select(item: SelectOption) {
    if (item.disabled) {
      return
    }
    this.selectOptions.forEach((i) => {
      i.selected = false
    })

    item.selected = !item.selected
    this.valueChanged.emit({ value: item.value })
  }
}
