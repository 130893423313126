import { Injectable } from '@angular/core'
import { HttpParams, HttpClient } from '@angular/common/http'
import { map } from 'rxjs/operators'
import { Observable } from 'rxjs'

import { environment } from '../../../environments/environment'
import { SelectOption } from '../interfaces/select-option.interface'

@Injectable({
  providedIn: 'root'
})
export class ResourceService {
  constructor(private http: HttpClient) {}

  list(resourceName: string, refineParams?: object): Observable<any> {
    let httpParams = new HttpParams()

    if (refineParams) {
      Object.keys(refineParams).forEach((key: string) => {
        if (Array.isArray(refineParams[key])) {
          refineParams[key].forEach((value: string) => {
            httpParams = httpParams.append(key, value)
          })
        } else {
          httpParams = httpParams.set(key, refineParams[key])
        }
      })
    }

    return this.http
      .get(`${environment.apiBaseUrl}/${resourceName}`, {
        params: httpParams
      })
      .pipe(
        map((res) => {
          return res
        })
      )
  }

  listSelectOptions(
    resourceName: string,
    refineParams?: object
  ): Promise<SelectOption[]> {
    return this.list(`${resourceName}/select-options`, refineParams).toPromise()
  }

  show(
    resourceName: string,
    id: number | string,
    suffix?: string
  ): Observable<any> {
    return this.http
      .get(
        `${environment.apiBaseUrl}/${resourceName}/${id}` +
          (suffix ? `/${suffix}` : '')
      )
      .pipe(
        map((res) => {
          return res
        })
      )
  }

  store(resourceName: string, body: any): Observable<any> {
    return this.http
      .post(`${environment.apiBaseUrl}/${resourceName}`, body)
      .pipe(
        map((response) => {
          return response
        })
      )
  }

  duplicate(resourceName: string, id: number | string): Observable<any> {
    return this.http
      .post(`${environment.apiBaseUrl}/${resourceName}/${id}/duplicate`, {})
      .pipe(
        map((res) => {
          return res
        })
      )
  }

  update(
    resourceName: string,
    id: number | string,
    body: any
  ): Observable<any> {
    return this.http
      .put(`${environment.apiBaseUrl}/${resourceName}/${id}`, body)
      .pipe(
        map((res) => {
          return res
        })
      )
  }

  patch(
    resourceName: string,
    id?: number | string,
    suffix?: string,
    formData?: FormData
  ): Observable<any> {
    const url =
      id && suffix
        ? `${environment.apiBaseUrl}/${resourceName}/${id}/${suffix}`
        : `${environment.apiBaseUrl}/${resourceName}`
    return this.http.patch(url, formData).pipe(
      map((res) => {
        return res
      })
    )
  }

  delete(resourceName: string, id: number | string): Observable<any> {
    return this.http
      .delete(`${environment.apiBaseUrl}/${resourceName}/${id}`)
      .pipe(
        map((res) => {
          return res
        })
      )
  }
}
