import { Component, OnInit } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import * as moment from 'moment'

import { AbcCreateEditComponent } from '../../../common/base-components/abc-create-edit/abc-create-edit.component'
import { InputType } from '../../../common/enums/input-type.enum'
import { Field } from '../../../common/interfaces/field.interface'
import { ResourceDefinition } from '../../../common/interfaces/resource-definition.interface'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { FlashMessageService } from '../../../common/services/flash-message.service'
import { ResourceService } from '../../../common/services/resource.service'
import { invoiceDefinition } from '../invoice.definition'

@Component({
  templateUrl:
    '../../../common/base-components/abc-create-edit/abc-create-edit.component.html',
  styleUrls: [
    '../../../common/base-components/abc-create-edit/abc-create-edit.component.scss'
  ]
})
export class InvoiceCreateEditComponent
  extends AbcCreateEditComponent
  implements OnInit {
  definition: ResourceDefinition = invoiceDefinition

  fields: Field[] = [
    {
      id: 'forecastedInvoiceId',
      hidden: true,
      label: 'Facture prévisionnelle',
      placeholder: 'Facture prévisionnelle',
      properties: {
        value: 'forecastedInvoiceId'
      },
      inputType: InputType.Number,
      validators: [],
      className: 'is-12 p-x-0-mobile'
    },
    {
      id: 'projectId',
      label: 'Projet',
      placeholder: 'Projet',
      properties: {
        projectId: 'projectId'
      },
      retrievedItemProperties: {
        projectId: 'project.id'
      },
      searchResources: ['projects'],
      maxSelectedItems: 1,
      inputType: InputType.MultiSearch,
      validators: [Validators.required],
      className: 'is-12 p-x-0-mobile'
    },
    {
      id: 'reference',
      label: 'Libellé de la prestation',
      properties: {
        value: 'reference'
      },
      inputType: InputType.Text,
      validators: [Validators.required],
      className: 'p-x-0-mobile is-6'
    },
    {
      id: 'issueDate',
      label: `Date de facturation`,
      properties: {
        value: 'issueDate'
      },
      initialValue: {
        value: moment().format('YYYY-MM-DD')
      },
      inputType: InputType.Datepicker,
      validators: [Validators.required],
      className: 'p-x-0-mobile is-6'
    },
    {
      label: `Date d'échéance`,
      properties: {
        value: 'dueDate'
      },
      initialValue: {
        value: moment().add('30', 'days').format('YYYY-MM-DD')
      },
      inputType: InputType.Datepicker,
      validators: [],
      className: 'p-x-0-mobile is-6'
    },
    {
      id: 'amount',
      label: 'Montant HT',
      properties: {
        value: 'amount'
      },
      validators: [Validators.required],
      inputType: InputType.Number,
      className: 'p-x-0-mobile is-6',
      onChange: (newValue: { value: number }) => {
        const taxesField: Field = this.getFieldById('taxes')

        this.setFieldValue(taxesField, {
          value: Math.round(newValue.value * 0.2 * 100) / 100
        })
      }
    },
    {
      id: 'taxes',
      label: 'TVA',
      properties: {
        value: 'taxes'
      },
      validators: [Validators.required],
      inputType: InputType.Number,
      className: 'p-x-0-mobile is-6'
    },
    {
      id: 'recurrenceDate',
      label: `Date de récurrence (si applicable)`,
      properties: {
        value: 'recurrenceDate'
      },
      inputType: InputType.Datepicker,
      validators: [],
      className: 'p-x-0-mobile is-6'
    }
  ]

  constructor(
    formBuilder: FormBuilder,
    router: Router,
    breadcrumbService: BreadcrumbService,
    resourceService: ResourceService,
    flashMessageService: FlashMessageService,
    activatedRoute: ActivatedRoute
  ) {
    super(
      formBuilder,
      router,
      breadcrumbService,
      resourceService,
      flashMessageService,
      activatedRoute
    )
  }

  ngOnInit() {
    this.initCreateEditView()
  }
}
