import {
  Directive,
  TemplateRef,
  ViewContainerRef,
  OnInit,
  Input
} from '@angular/core'
import { User } from '../../resources/user/user.interface'
import { AuthService } from '../services/auth.service'

type LogicalOperator = 'AND' | 'OR'

@Directive({
  selector: '[abcHasPermission]'
})
export class HasPermissionDirective implements OnInit {
  @Input() set abcHasPermission(val: string | string[]) {
    this.permissions = typeof val === 'string' ? [val] : val
  }
  @Input() set abcHasPermissionLogicalOperator(val) {
    this.logicalOperator = val
  }

  @Input() set abcHasPermissionOpposite(val) {
    this.opposite = val
  }

  private logicalOperator: LogicalOperator = 'AND'
  private permissions: string[]
  private isHidden = true
  private opposite = false

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.authService.currentUser.subscribe((user: User) => {
      const hasPermission: boolean = this.opposite
        ? !this.checkPermission(user)
        : this.checkPermission(user)

      if (hasPermission) {
        if (this.isHidden) {
          this.viewContainer.createEmbeddedView(this.templateRef)
          this.isHidden = false
        }
      } else {
        this.isHidden = true
        this.viewContainer.clear()
      }
    })
  }

  checkPermission(user: User): boolean {
    if (!user) {
      return false
    }

    const currentUserPermissions: string[] = user.role.permissions.map(
      (p) => p.name
    )

    if (this.logicalOperator === 'OR') {
      return this.permissions.some((permission: string) =>
        currentUserPermissions.includes(permission)
      )
    } else if (this.logicalOperator === 'AND') {
      return this.permissions.every((permission: string) =>
        currentUserPermissions.includes(permission)
      )
    }
  }
}
