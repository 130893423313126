import { Component, Input, OnInit } from '@angular/core'
import { ChartOptions } from 'chart.js'
import { ResourceDefinition } from '../../../common/interfaces/resource-definition.interface'
import { Nature } from '../../../resources/nature/nature.interface'
import { projectDefinition } from '../../../resources/project/project.definition'
import { User } from '../../../resources/user/user.interface'
import { projectAnalyticsViewDefinition } from '../project-analytics-view.definition'

@Component({
  selector: 'app-user-production-card',
  templateUrl: './user-production-card.component.html',
  styleUrls: ['./user-production-card.component.scss']
})
export class UserProductionCardComponent implements OnInit {
  @Input() user: User

  pieChartData: number[]
  pieChartLabels: string[]
  pieChartColors: string[]
  pieChartOptions: ChartOptions = {
    legend: { display: false },
    responsive: true
  }

  totalDaysOfWork: number
  totalValue: number

  projectDefinition: ResourceDefinition = projectAnalyticsViewDefinition

  ngOnInit() {
    this.totalDaysOfWork = this.user.projectNatures.reduce(
      (sum: number, curr: Nature) => sum + curr.plannedWorkDaysOfWork,
      0
    )
    this.totalValue = this.user.projectNatures.reduce(
      (sum: number, curr: Nature) => sum + curr.plannedWorkValue,
      0
    )

    // Pie chart.
    this.pieChartData = this.user.projectNatures.map(
      (n: Nature) => n.plannedWorkDaysOfWork
    )
    this.pieChartLabels = this.user.projectNatures.map((n: Nature) => n.name)
    this.pieChartColors = this.user.projectNatures.map((n: Nature) => n.color)

    this.user.projects.sort((a, b) => b.plannedWorkValue - a.plannedWorkValue)
  }
}
