import { Injectable } from '@angular/core'

import { Subject } from 'rxjs'
import { appConstants } from '../../app.constants'

@Injectable({
  providedIn: 'root'
})
export class FlashMessageService {
  public flashMessage: Subject<{
    message: string
    className: string
  }> = new Subject()

  success(message: string, sticky = false) {
    this.flashMessage.next({ message, className: 'notification is-success' })
    setTimeout(
      () => {
        this.flashMessage.next(null)
      },
      sticky ? 9999999 : appConstants.FLASH_MESSAGE_TIMEOUT
    )
  }

  error(message: string, sticky = false) {
    this.flashMessage.next({ message, className: 'notification is-danger' })
    setTimeout(
      () => {
        this.flashMessage.next(null)
      },
      sticky ? 9999999 : appConstants.FLASH_MESSAGE_TIMEOUT
    )
  }

  info(message: string, sticky = false) {
    this.flashMessage.next({ message, className: 'notification is-info' })
    setTimeout(
      () => {
        this.flashMessage.next(null)
      },
      sticky ? 9999999 : appConstants.FLASH_MESSAGE_TIMEOUT
    )
  }
}
