import { DecimalPipe, registerLocaleData } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'
import localeFr from '@angular/common/locales/fr'
import { LOCALE_ID, NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { JwtModule } from '@auth0/angular-jwt'
import bugsnag from '@bugsnag/js'
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular'
import { CKEditorModule } from '@ckeditor/ckeditor5-angular'
import { CalendarModule, DateAdapter } from 'angular-calendar'
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns'
import { AngularGooglePlaceModule } from 'angular-google-place'
import { MyDatePickerModule } from 'mydatepicker'
import { ChartsModule } from 'ng2-charts'
import { DndModule } from 'ngx-drag-drop'

import { environment } from '../environments/environment'
import { ProductionAnalysisComponent } from './analytics/production-analysis/production-analysis.component'
import { UserProductionCardComponent } from './analytics/production-analysis/user-production-card/user-production-card.component'
import { TurnoverChartComponent } from './analytics/turnover/turnover-chart/turnover-chart.component'
import { TurnoverComponent } from './analytics/turnover/turnover.component'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { AbcCreateEditComponent } from './common/base-components/abc-create-edit/abc-create-edit.component'
import { AbcListComponent } from './common/base-components/abc-list/abc-list.component'
import { ChartComponent } from './common/base-components/chart.component'
import { DatepickerComponent } from './common/base-components/datepicker.component'
import { HasPermissionDirective } from './common/directives/has-permission.directive'
import { AbcInputComponent } from './common/elements/inputs/abc-input/abc-input.component'
import { CheckboxInputComponent } from './common/elements/inputs/checkbox-input/checkbox-input.component'
import { ColorPickerInputComponent } from './common/elements/inputs/color-picker-input/color-picker-input.component'
import { DateRangeInputComponent } from './common/elements/inputs/date-range-input/date-range-input.component'
import { DatepickerInputComponent } from './common/elements/inputs/datepicker-input/datepicker-input.component'
import { EmailInputComponent } from './common/elements/inputs/email-input/email-input.component'
import { FileInputComponent } from './common/elements/inputs/file-input/file-input.component'
import { GooglePlacesInputComponent } from './common/elements/inputs/google-places-input/google-places-input.component'
import { ImageInputComponent } from './common/elements/inputs/image-input/image-input.component'
import { MultiSearchInputComponent } from './common/elements/inputs/multi-search-input/multi-search-input.component'
import { MultiSelectInputComponent } from './common/elements/inputs/multi-select-input/multi-select-input.component'
import { NumberInputComponent } from './common/elements/inputs/number-input/number-input.component'
import { PasswordInputComponent } from './common/elements/inputs/password-input/password-input.component'
import { RadioInputComponent } from './common/elements/inputs/radio-input/radio-input.component'
import { RichTextInputComponent } from './common/elements/inputs/rich-text-input/rich-text-input.component'
import { SelectInputComponent } from './common/elements/inputs/select-input/select-input.component'
import { TelInputComponent } from './common/elements/inputs/tel-input/tel-input.component'
import { TextInputComponent } from './common/elements/inputs/text-input/text-input.component'
import { TextareaInputComponent } from './common/elements/inputs/textarea-input/textarea-input.component'
import { TimeInputComponent } from './common/elements/inputs/time-input/time-input.component'
import { ToggleInputComponent } from './common/elements/inputs/toggle-input/toggle-input.component'
import { SideMenuComponent } from './common/elements/navigation/side-menu/side-menu.component'
import { TopMenuComponent } from './common/elements/navigation/top-menu/top-menu.component'
import { TouchMenuComponent } from './common/elements/navigation/touch-menu/touch-menu.component'
import { ActionDropdownComponent } from './common/elements/partials/action-dropdown/action-dropdown.component'
import { BreadcrumbsComponent } from './common/elements/partials/breadcrumbs/breadcrumbs.component'
import { ConfirmDeleteModalComponent } from './common/elements/partials/confirm-delete-modal/confirm-delete-modal.component'
import { FlashMessageComponent } from './common/elements/partials/flash-message/flash-message.component'
import { ImageComponent } from './common/elements/partials/image/image.component'
import { MetaComponent } from './common/elements/partials/meta/meta.component'
import { PaginationComponent } from './common/elements/partials/pagination/pagination.component'
import { TableComponent } from './common/elements/partials/table/table.component'
import { AddressYieldComponent } from './common/elements/yields/address-yield/address-yield.component'
import { AnalogProgressBarYieldComponent } from './common/elements/yields/analog-progress-bar-yield/analog-progress-bar-yield.component'
import { ColorYieldComponent } from './common/elements/yields/color-yield/color-yield.component'
import { CurrencyYieldComponent } from './common/elements/yields/currency-yield/currency-yield.component'
import { DateYieldComponent } from './common/elements/yields/date-yield/date-yield.component'
import { DownloadYieldComponent } from './common/elements/yields/download-yield/download-yield.component'
import { FileIconYieldComponent } from './common/elements/yields/file-icon-yield/file-icon-yield.component'
import { ImageYieldComponent } from './common/elements/yields/image-yield/image-yield.component'
import { ProgressBarYieldComponent } from './common/elements/yields/progress-bar-yield/progress-bar-yield.component'
import { EurosPipe } from './common/pipes/euros.pipe'
import { StripHtmlPipe } from './common/pipes/strip-html.pipe'
import { TruncatePipe } from './common/pipes/truncate.pipe'
import { AuthService } from './common/services/auth.service'
import { BreadcrumbService } from './common/services/breadcrumb.service'
import { EventService } from './common/services/event.service'
import { ViewportService } from './common/services/viewport.service'
import { ForgotPasswordComponent } from './pages/auth/forgot-password/forgot-password.component'
import { LoginComponent } from './pages/auth/login/login.component'
import { LogoutComponent } from './pages/auth/logout/logout.component'
import { ResetPasswordComponent } from './pages/auth/reset-password/reset-password.component'
import { Error404Component } from './pages/error404/error404.component'
import { HomeComponent } from './pages/home/home.component'
import { CustomerCreateEditComponent } from './resources/customer/customer-create-edit/customer-create-edit.component'
import { CustomerDetailComponent } from './resources/customer/customer-detail/customer-detail.component'
import { CustomerListComponent } from './resources/customer/customer-list/customer-list.component'
import { ForecastedInvoiceCreateEditComponent } from './resources/forecasted-invoice/forecasted-invoice-create-edit/forecasted-invoice-create-edit.component'
import { ForecastedInvoiceListComponent } from './resources/forecasted-invoice/forecasted-invoice-list/forecasted-invoice-list.component'
import { InvoiceCreateEditComponent } from './resources/invoice/invoice-create-edit/invoice-create-edit.component'
import { InvoiceListComponent } from './resources/invoice/invoice-list/invoice-list.component'
import { InvoicePaymentModalComponent } from './resources/invoice/invoice-payment-modal/invoice-payment-modal.component'
import { NatureCreateEditComponent } from './resources/nature/nature-create-edit/nature-create-edit.component'
import { NatureListComponent } from './resources/nature/nature-list/nature-list.component'
import { PaymentListComponent } from './resources/payment/payment-list/payment-list.component'
import { HolidayCreateEditModalComponent } from './resources/planned-work/holiday-create-edit-modal/holiday-create-edit-modal.component'
import { PlannedWorkCreateEditModalComponent } from './resources/planned-work/planned-work-create-edit-modal/planned-work-create-edit-modal.component'
import { PositionCreateEditComponent } from './resources/position/position-create-edit/position-create-edit.component'
import { PositionListComponent } from './resources/position/position-list/position-list.component'
import { ProjectAccountingComponent } from './resources/project/project-accounting/project-accounting.component'
import { ProjectCreateEditComponent } from './resources/project/project-create-edit/project-create-edit.component'
import { ProjectDetailComponent } from './resources/project/project-detail/project-detail.component'
import { ProjectListComponent } from './resources/project/project-list/project-list.component'
import { ProjectTimelineComponent } from './resources/project/project-timeline/project-timeline.component'
import { ReferentCreateEditComponent } from './resources/referent/referent-create-edit/referent-create-edit.component'
import { ReferentListComponent } from './resources/referent/referent-list/referent-list.component'
import { RoleCreateEditComponent } from './resources/role/role-create-edit/role-create-edit.component'
import { RoleListComponent } from './resources/role/role-list/role-list.component'
import { UserAgendaComponent } from './resources/user/user-agenda/user-agenda.component'
import { UserCreateEditComponent } from './resources/user/user-create-edit/user-create-edit.component'
import { UserDetailComponent } from './resources/user/user-detail/user-detail.component'
import { UserListComponent } from './resources/user/user-list/user-list.component';
import { IconYieldComponent } from './common/elements/yields/icon-yield/icon-yield.component'

// Register locale data
registerLocaleData(localeFr, 'fr')

export function jwtTokenGetter() {
  return localStorage.getItem(environment.tokenName)
}

// configure Bugsnag asap
const bugsnagClient = bugsnag({
  apiKey: environment.bugsnagApiKey,
  releaseStage: environment.production ? 'production' : 'development',
  notifyReleaseStages: ['production']
})

// create a factory which will return the bugsnag error handler
export function errorHandlerFactory() {
  return new BugsnagErrorHandler(bugsnagClient)
}

@NgModule({
  declarations: [
    AppComponent,
    LogoutComponent,
    LoginComponent,
    HomeComponent,
    SideMenuComponent,
    TopMenuComponent,
    RoleCreateEditComponent,
    MetaComponent,
    PaginationComponent,
    ConfirmDeleteModalComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    BreadcrumbsComponent,
    GooglePlacesInputComponent,
    Error404Component,
    TouchMenuComponent,
    TruncatePipe,
    TableComponent,
    ActionDropdownComponent,
    HasPermissionDirective,
    RichTextInputComponent,
    MultiSearchInputComponent,
    MultiSelectInputComponent,
    ColorPickerInputComponent,
    AddressYieldComponent,
    ImageYieldComponent,
    DatepickerInputComponent,
    SelectInputComponent,
    CheckboxInputComponent,
    TextInputComponent,
    EmailInputComponent,
    NumberInputComponent,
    TelInputComponent,
    DateRangeInputComponent,
    FileInputComponent,
    ToggleInputComponent,
    AbcInputComponent,
    StripHtmlPipe,
    PasswordInputComponent,
    ImageInputComponent,
    DownloadYieldComponent,
    UserDetailComponent,
    ColorYieldComponent,
    CurrencyYieldComponent,
    DateYieldComponent,
    ProgressBarYieldComponent,
    FileIconYieldComponent,
    AnalogProgressBarYieldComponent,
    FlashMessageComponent,
    EurosPipe,
    ImageComponent,
    CustomerDetailComponent,
    ProjectDetailComponent,
    ProjectTimelineComponent,
    ProjectAccountingComponent,
    RadioInputComponent,
    TimeInputComponent,
    TextareaInputComponent,
    AbcCreateEditComponent,
    AbcListComponent,
    NatureCreateEditComponent,
    NatureListComponent,
    ReferentListComponent,
    CustomerListComponent,
    PositionListComponent,
    UserListComponent,
    InvoiceListComponent,
    InvoicePaymentModalComponent,
    RoleListComponent,
    ProjectListComponent,

    ProjectCreateEditComponent,
    ReferentCreateEditComponent,
    CustomerCreateEditComponent,
    PositionCreateEditComponent,
    UserCreateEditComponent,
    InvoiceCreateEditComponent,
    DatepickerComponent,
    ChartComponent,
    UserAgendaComponent,
    PlannedWorkCreateEditModalComponent,
    ProductionAnalysisComponent,
    UserProductionCardComponent,
    HolidayCreateEditModalComponent,
    ForecastedInvoiceListComponent,
    ForecastedInvoiceCreateEditComponent,
    TurnoverComponent,
    TurnoverChartComponent,
    PaymentListComponent,
    IconYieldComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    AngularGooglePlaceModule,
    MyDatePickerModule,
    CKEditorModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: jwtTokenGetter,
        whitelistedDomains: environment.tokenWhitelistedDomains
      }
    }),
    DndModule,
    BrowserAnimationsModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    ChartsModule
  ],
  providers: [
    AuthService,
    EventService,
    BreadcrumbService,
    ViewportService,
    { provide: LOCALE_ID, useValue: 'fr' },
    EurosPipe,
    DecimalPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
