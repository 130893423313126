import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { combineLatest } from 'rxjs'
import { map } from 'rxjs/operators'

import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { ResourceService } from '../../../common/services/resource.service'
import { Project } from '../project.interface'

@Component({
  selector: 'app-project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.scss']
})
export class ProjectDetailComponent implements OnInit {
  project: Project

  selectedTab: string
  invoiceToPay: string

  constructor(
    private activatedRoute: ActivatedRoute,
    private resourceService: ResourceService,
    private breadcrumbService: BreadcrumbService
  ) {}

  ngOnInit() {
    combineLatest([this.activatedRoute.params, this.activatedRoute.queryParams])
      .pipe(
        map((res) => ({
          params: res[0],
          queryParams: res[1]
        }))
      )
      .subscribe(
        ({
          params,
          queryParams
        }: {
          params: { id: string }
          queryParams: any
        }) => {
          this.getProject(params.id)

          if (queryParams.invoiceToPay) {
            this.invoiceToPay = queryParams.invoiceToPay
          }

          this.selectedTab = queryParams.selectedTab || 'activity'
        }
      )
  }

  getProject(id: string) {
    this.project = null
    this.resourceService.show('projects', id).subscribe((res: Project) => {
      this.project = res

      // Set Meta and Breadcrumbs.
      this.breadcrumbService.breadcrumbLinks.next([
        {
          label: 'Projets',
          path: '/projets'
        },
        {
          label: this.project.name
        }
      ])
    })
  }
}
