import { Gender } from '../../common/enums/gender.enum'
import { LinkType } from '../../common/enums/link-type.enum'
import { YieldType } from '../../common/enums/yield-type.enum'
import { ResourceDefinition } from '../../common/interfaces/resource-definition.interface'

export const projectDefinition: ResourceDefinition = {
  title: 'Projets',
  nameSingular: 'projet',
  namePlural: 'projets',
  hasDetailPage: true,
  hasListPage: true,
  gender: Gender.Masculine,
  slug: 'projects',
  path: 'projets',
  buttons: [LinkType.CREATE, LinkType.EXPORT],
  defaultLink: LinkType.DETAIL,
  childrenThatPreventDelete: [
    {
      propName: 'invoiceCount',
      preventDeleteMessage: `Une ou plusieurs factures sont reliées à ce projet`
    }
  ],
  actionButtons: [],
  dropdownLinks: [
    {
      type: LinkType.DETAIL,
      permission: 'readProjects'
    },
    {
      type: LinkType.EDIT,
      permission: 'editProjects'
    },
    {
      type: LinkType.DUPLICATE,
      permission: 'addProjects'
    },
    {
      type: LinkType.DELETE,
      permission: 'deleteProjects'
    }
  ],
  yields: [
    {
      label: 'Projet',
      property: 'nature.color',
      className: 'has-mini-circle',
      secondProperty: 'name',
      orderByProperty: 'name',
      type: YieldType.Color
    },
    {
      label: 'Client',
      property: 'customer.name'
    },
    {
      label: 'Début',
      property: 'startDate',
      type: YieldType.Date
    },
    {
      label: 'Fin',
      property: 'endDate',
      type: YieldType.Date
    },
    {
      label: 'Responsable',
      property: 'referent.name'
    },
    {
      label: 'Montant facturé',
      property: 'totalInvoicesAmount',
      type: YieldType.Currency
    },
    {
      label: 'Statut',
      property: 'status',
      type: YieldType.ProgressBar
    }
  ]
}
