import { Gender } from '../../common/enums/gender.enum'
import { LinkType } from '../../common/enums/link-type.enum'
import { YieldType } from '../../common/enums/yield-type.enum'
import { ResourceDefinition } from '../../common/interfaces/resource-definition.interface'
import { ForecastedInvoice } from './forecasted-invoice.interface'

export const forecastedInvoiceDefinition: ResourceDefinition = {
  title: 'Factures prévisionnelles',
  nameSingular: 'facture prévisionnelle',
  namePlural: 'fatures prévisionnelles',
  gender: Gender.Feminine,
  slug: 'forecasted-invoices',
  path: 'factures-previsionnelles',
  hasDetailPage: false,
  hasListPage: true,
  buttons: [LinkType.CREATE],
  defaultLink: null,
  childrenThatPreventDelete: [],
  actionButtons: [],
  dropdownLinks: [
    {
      type: LinkType.EDIT,
      permission: 'editInvoices'
    },
    {
      type: LinkType.DELETE,
      permission: 'deleteInvoices'
    },
    {
      type: LinkType.CUSTOM,
      permission: 'addInvoices',
      label: 'Convertir en facture',
      linkAction: (fI: ForecastedInvoice) => ({
        path: `factures/create`,
        queryParams: {
          specialRules: JSON.stringify([
            {
              fieldId: 'forecastedInvoiceId',
              hidden: true,
              forcedValue: {
                value: fI.id
              }
            },
            {
              fieldId: 'projectId',
              forcedValue: {
                projectId: fI.project.id
              }
            },
            {
              fieldId: 'reference',
              forcedValue: {
                value: fI.reference
              }
            },
            {
              fieldId: 'issueDate',
              forcedValue: {
                value: fI.issueDate
              }
            },
            {
              fieldId: 'recurrenceDate',
              forcedValue: {
                value: fI.recurrenceDate
              }
            },
            {
              fieldId: 'amount',
              forcedValue: {
                value: fI.amount
              }
            },
            {
              fieldId: 'taxes',
              forcedValue: {
                value: fI.taxes
              }
            }
          ])
        }
      }),
      condition: (fI: ForecastedInvoice) => !fI.invoice
    }
  ],
  yields: [
    {
      label: 'Libellé',
      property: 'reference'
    },

    {
      label: 'Récurrence',
      property: 'isRecurrent',
      type: YieldType.Icon,
      disableOrderBy: true
    },
    {
      label: 'Projet',
      property: 'project.name'
    },
    {
      label: 'Client',
      property: 'project.customer.name',
      orderByProperty: 'customer.name'
    },
    {
      label: `Date prévisionnelle`,
      property: 'issueDate',
      type: YieldType.Date
    },
    {
      label: 'Montant HT',
      property: 'amount',
      type: YieldType.Currency
    },
    {
      label: 'Probabilité',
      property: 'issueProbability',
      type: YieldType.Text
    },
    {
      label: 'Facture',
      property: 'invoice.number'
    }
  ],
  keyNumbers: [
    {
      label: 'Total estimations (HT)',
      className: 'label',
      extraParams: {
        calculateTotal: '1'
      }
    },
    {
      label: 'A facturer (HT)',
      className: 'label',
      extraParams: {
        calculateTotal: '1',
        withoutInvoiceOnly: 'true'
      }
    }
  ]
}
