import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Params, Router } from '@angular/router'
import * as moment from 'moment'

import { AbcListComponent } from '../../common/base-components/abc-list/abc-list.component'
import { InputType } from '../../common/enums/input-type.enum'
import { Filter } from '../../common/interfaces/filter.interface'
import { ResourceDefinition } from '../../common/interfaces/resource-definition.interface'
import { BreadcrumbService } from '../../common/services/breadcrumb.service'
import { FlashMessageService } from '../../common/services/flash-message.service'
import { ResourceService } from '../../common/services/resource.service'
import { forecastedInvoiceDefinition } from '../../resources/forecasted-invoice/forecasted-invoice.definition'
import { ForecastedInvoice } from '../../resources/forecasted-invoice/forecasted-invoice.interface'
import { invoiceDefinition } from '../../resources/invoice/invoice.definition'
import { Invoice } from '../../resources/invoice/invoice.interface'
import { Nature } from '../../resources/nature/nature.interface'
import { paymentDefinition } from '../../resources/payment/payment.definition'
import { Payment } from '../../resources/payment/payment.interface'
import { turnoverDefinition } from './turnover-definition'

@Component({
  selector: 'app-turnover',
  templateUrl: './turnover.component.html',
  styleUrls: ['./turnover.component.scss']
})
export class TurnoverComponent extends AbcListComponent implements OnInit {
  definition: ResourceDefinition = turnoverDefinition

  filters: Filter[] = [
    {
      label: 'Année',
      placeholder: 'Année..',
      properties: {
        value: 'year'
      },

      selectOptions: [-1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(
        (yearNumber: number) => {
          const year: string = moment().subtract(yearNumber, 'y').format('YYYY')
          return {
            label: year,
            value: year
          }
        }
      ),
      inputType: InputType.Select,
      className: 'is-12-mobile is-6-tablet is-3 p-x-0-mobile'
    },
    {
      label: 'Mois',
      placeholder: 'Mois..',
      properties: {
        value: 'month'
      },
      selectOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(
        (monthNumber: number) => {
          const month: moment.Moment = moment(
            `${moment().format('YYYY')}-${monthNumber}`,
            'YYYY-M'
          )

          return {
            label: month.locale('fr').format('MMMM'),
            value: monthNumber.toString()
          }
        }
      ),
      inputType: InputType.Select,
      className: 'is-12-mobile is-6-tablet is-3 p-x-0-mobile'
    },
    {
      label: 'Natures de projet',
      properties: {
        value: 'natureIds'
      },
      placeholder: 'Tous',
      selectOptions: () =>
        this.customResourceService
          .list('natures', { withoutPagination: true })
          .toPromise()
          .then((natureRes: Nature[]) =>
            natureRes.map((n) => ({
              label: n.name,
              value: n.id.toString()
            }))
          ),
      inputType: InputType.MultiSelect,
      className: 'is-12-mobile is-6-tablet is-3 p-x-0-mobile'
    },
    {
      label: 'Clients',
      placeholder: 'Filter par client...',
      properties: {
        customerIds: 'customerIds'
      },
      searchResources: ['customers'],
      inputType: InputType.MultiSearch,
      className: 'is-12-mobile is-6-tablet is-3 p-x-0-mobile'
    }
  ]

  monthForecastedInvoices: ForecastedInvoice[]
  monthInvoices: Invoice[]
  monthPayments: Payment[]

  forecastedInvoiceDefinition: ResourceDefinition = forecastedInvoiceDefinition
  invoiceDefinition: ResourceDefinition = invoiceDefinition
  paymentDefinition: ResourceDefinition = paymentDefinition

  monthTotalAmounts: {
    forecastedInvoices?: number
    invoices?: number
    payments?: number
  } = {}

  constructor(
    router: Router,
    activatedRoute: ActivatedRoute,
    resourceService: ResourceService,
    breadcrumbService: BreadcrumbService,
    flashMessageService: FlashMessageService,
    private customResourceService: ResourceService,
    private customActivatedRoute: ActivatedRoute
  ) {
    super(
      router,
      activatedRoute,
      breadcrumbService,
      resourceService,
      flashMessageService
    )
  }

  ngOnInit() {
    this.initListView()

    // Month recap.
    this.customActivatedRoute.queryParams.subscribe(
      (queryParams: { month: string; year: string }) => {
        const listParams: Params = {
          dateFrom: `${queryParams.year}-${queryParams.month}-01`,
          dateTo: moment(
            `${queryParams.year}-${queryParams.month}-01`,
            'YYYY-MM-DD'
          )
            .endOf('month')
            .format('YYYY-MM-DD'),
          withoutPagination: true,
          orderBy: 'issueDate'
        }

        this.customResourceService
          .list('forecasted-invoices', listParams)
          .subscribe((forecastedInvoiceRes: ForecastedInvoice[]) => {
            this.monthForecastedInvoices = forecastedInvoiceRes
            this.monthTotalAmounts.forecastedInvoices =
              this.monthForecastedInvoices.reduce(
                (sum: number, curr: ForecastedInvoice) => sum + curr.amount,
                0
              )
          })

        this.customResourceService
          .list('invoices', listParams)
          .subscribe((invoiceRes: Invoice[]) => {
            this.monthInvoices = invoiceRes

            this.monthTotalAmounts.invoices = this.monthInvoices.reduce(
              (sum: number, curr: Invoice) => sum + curr.amount,
              0
            )
          })

        this.customResourceService
          .list('payments', Object.assign(listParams, { orderBy: 'date' }))
          .subscribe((paymentRes: Payment[]) => {
            this.monthPayments = paymentRes
            this.monthTotalAmounts.payments = this.monthPayments.reduce(
              (sum: number, curr: Payment) => sum + curr.amount,
              0
            )
          })
      }
    )
    // Monthly recap data.
  }
}
