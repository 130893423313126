import { Component, Input, OnChanges } from '@angular/core'
import { ChartDataSets, ChartOptions, ChartTooltipItem } from 'chart.js'
import * as moment from 'moment'
import { Label } from 'ng2-charts'

import { ChartComponent } from '../../../common/base-components/chart.component'
import { Paginator } from '../../../common/interfaces/paginator.interface'

@Component({
  selector: 'app-turnover-chart',
  templateUrl: './turnover-chart.component.html',
  styleUrls: ['./turnover-chart.component.scss']
})
export class TurnoverChartComponent
  extends ChartComponent
  implements OnChanges
{
  @Input() data: Paginator<any>

  chartOptions: ChartOptions = {
    tooltips: {
      callbacks: {
        label: (item: ChartTooltipItem, data) => {
          return `${data.datasets[item.datasetIndex].label} : ${item.yLabel} €`
        }
      },
      backgroundColor: '#303032',
      titleFontSize: 13,
      titleFontColor: '#fff',
      bodyFontColor: '#fff',
      bodyFontSize: 13,
      displayColors: false,
      cornerRadius: 2,
      yPadding: 11,
      xPadding: 15
    },
    responsive: true,
    maintainAspectRatio: false,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
            drawBorder: false
          }
        }
      ],
      yAxes: [
        {
          gridLines: {
            color: '#EBEEF1',
            drawBorder: false,
            zeroLineColor: '#EBEEF1'
          },
          ticks: {
            callback: (value: number) => `${value} €`
          }
        }
      ]
    }
  }
  chartLabels: Label[]
  chartData: ChartDataSets[]

  transparentColor = 'rgba(0,0,0,0)'

  totalInvoiceAmount: number
  totalForecastedInvoiceAmount: number
  totalPaymentAmount: number

  constructor() {
    super()
  }

  ngOnChanges() {
    this.totalInvoiceAmount = (this.data as any).invoiceAmountsPerMonth.reduce(
      (sum, curr) => sum + curr,
      0
    )
    this.totalForecastedInvoiceAmount = (
      this.data as any
    ).forecastedInvoiceAmountsPerMonth.reduce((sum, curr) => sum + curr, 0)
    this.totalPaymentAmount = (this.data as any).paymentAmountsPerMonth.reduce(
      (sum, curr) => sum + curr,
      0
    )

    this.chartLabels = moment.monthsShort()

    this.chartData = [
      {
        label: 'Estimé',
        // We are using an ABC List so the parent component expects to receive a paginator but we are receiving a different object.
        data: (this.data as any).forecastedInvoiceAmountsPerMonth,
        backgroundColor: '#1EB1E8',
        hoverBackgroundColor: '#1EB1E8',
        barThickness: 19,
        borderColor: this.transparentColor,
        hoverBorderColor: this.transparentColor,
        borderWidth: 10
      },
      {
        label: 'Facturé',
        data: (this.data as any).invoiceAmountsPerMonth,
        backgroundColor: '#57B3A0',
        hoverBackgroundColor: '#57B3A0',
        barThickness: 19,
        borderColor: this.transparentColor,
        hoverBorderColor: this.transparentColor,
        borderWidth: 10
      },
      {
        label: 'Encaissé',
        data: (this.data as any).paymentAmountsPerMonth,
        backgroundColor: '#5C1EE8',
        hoverBackgroundColor: '#5C1EE8',
        barThickness: 19,
        borderColor: this.transparentColor,
        hoverBorderColor: this.transparentColor,
        borderWidth: 10
      }
    ]
  }
}
