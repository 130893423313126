import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

import { ProductionAnalysisComponent } from './analytics/production-analysis/production-analysis.component'
import { TurnoverComponent } from './analytics/turnover/turnover.component'
import { AuthGuard } from './common/guards/auth.guard'
import { PermissionGuard } from './common/guards/permission.guard'
import { ForgotPasswordComponent } from './pages/auth/forgot-password/forgot-password.component'
import { LoginComponent } from './pages/auth/login/login.component'
import { LogoutComponent } from './pages/auth/logout/logout.component'
import { ResetPasswordComponent } from './pages/auth/reset-password/reset-password.component'
import { Error404Component } from './pages/error404/error404.component'
import { HomeComponent } from './pages/home/home.component'
import { CustomerCreateEditComponent } from './resources/customer/customer-create-edit/customer-create-edit.component'
import { CustomerDetailComponent } from './resources/customer/customer-detail/customer-detail.component'
import { CustomerListComponent } from './resources/customer/customer-list/customer-list.component'
import { ForecastedInvoiceCreateEditComponent } from './resources/forecasted-invoice/forecasted-invoice-create-edit/forecasted-invoice-create-edit.component'
import { ForecastedInvoiceListComponent } from './resources/forecasted-invoice/forecasted-invoice-list/forecasted-invoice-list.component'
import { InvoiceCreateEditComponent } from './resources/invoice/invoice-create-edit/invoice-create-edit.component'
import { InvoiceListComponent } from './resources/invoice/invoice-list/invoice-list.component'
import { NatureCreateEditComponent } from './resources/nature/nature-create-edit/nature-create-edit.component'
import { NatureListComponent } from './resources/nature/nature-list/nature-list.component'
import { PaymentListComponent } from './resources/payment/payment-list/payment-list.component'
import { PositionCreateEditComponent } from './resources/position/position-create-edit/position-create-edit.component'
import { PositionListComponent } from './resources/position/position-list/position-list.component'
import { ProjectCreateEditComponent } from './resources/project/project-create-edit/project-create-edit.component'
import { ProjectDetailComponent } from './resources/project/project-detail/project-detail.component'
import { ProjectListComponent } from './resources/project/project-list/project-list.component'
import { ReferentCreateEditComponent } from './resources/referent/referent-create-edit/referent-create-edit.component'
import { ReferentListComponent } from './resources/referent/referent-list/referent-list.component'
import { RoleCreateEditComponent } from './resources/role/role-create-edit/role-create-edit.component'
import { RoleListComponent } from './resources/role/role-list/role-list.component'
import { UserCreateEditComponent } from './resources/user/user-create-edit/user-create-edit.component'
import { UserDetailComponent } from './resources/user/user-detail/user-detail.component'
import { UserListComponent } from './resources/user/user-list/user-list.component'

const routes: Routes = [
  // * Auth
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'logout',
    component: LogoutComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent
  },

  // * Pages
  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthGuard]
  },

  // * Users
  {
    path: 'collaborateurs',
    component: UserListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: { permission: 'browseUsers' }
  },
  {
    path: 'collaborateurs/create',
    component: UserCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'addUsers',
      mode: 'create'
    }
  },
  {
    path: 'collaborateurs/:id',
    component: UserDetailComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permissions: ['readUsers', 'readOwnUsers']
    }
  },
  {
    path: 'collaborateurs/myself/edit',
    component: UserCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    // This route doesn't require "editUsers" because users can edit themselves.
    data: {
      mode: 'edit',
      editMyself: true
    }
  },
  {
    path: 'collaborateurs/:id/edit',
    component: UserCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: 'edit',
      permission: 'editUsers'
    }
  },
  // * Positions
  {
    path: 'profils',
    component: PositionListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'browsePositions'
    }
  },
  {
    path: 'profils/:create',
    component: PositionCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'addPositions',
      mode: 'create'
    }
  },
  {
    path: 'profils/:id/edit',
    component: PositionCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'editPositions',
      mode: 'edit'
    }
  },

  // * Customers
  {
    path: 'clients',
    component: CustomerListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'browseCustomers'
    }
  },
  {
    path: 'clients/create',
    component: CustomerCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'addCustomers',
      mode: 'create'
    }
  },
  {
    path: 'clients/:id/edit',
    component: CustomerCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'editCustomers',
      mode: 'edit'
    }
  },
  {
    path: 'clients/:id',
    component: CustomerDetailComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'readCustomers'
    }
  },
  // * Referents
  {
    path: 'responsables',
    component: ReferentListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'browseReferents'
    }
  },
  {
    path: 'responsables/create',
    component: ReferentCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'addReferents',
      mode: 'create'
    }
  },
  {
    path: 'responsables/:id/edit',
    component: ReferentCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'editReferents',
      mode: 'edit'
    }
  },
  // * Natures
  {
    path: 'natures',
    component: NatureListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'browseNatures'
    }
  },
  {
    path: 'natures/create',
    component: NatureCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'addNatures',
      mode: 'create'
    }
  },
  {
    path: 'natures/:id/edit',
    component: NatureCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'editNatures',
      mode: 'edit'
    }
  },
  // * Projects
  {
    path: 'projets',
    component: ProjectListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'browseProjects'
    }
  },
  {
    path: 'projets/create',
    component: ProjectCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'addProjects',
      mode: 'create'
    }
  },
  {
    path: 'projets/:id/edit',
    component: ProjectCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'editProjects',
      mode: 'edit'
    }
  },
  {
    path: 'projets/:id',
    component: ProjectDetailComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'readProjects'
    }
  },
  // * Roles
  {
    path: 'roles',
    component: RoleListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'browseRoles'
    }
  },
  {
    path: 'roles/create',
    component: RoleCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'addRoles',
      mode: 'create'
    }
  },
  {
    path: 'roles/:id/edit',
    component: RoleCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'editRoles',
      mode: 'edit'
    }
  },

  // Invoices.
  {
    path: 'factures',
    component: InvoiceListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'browseInvoices'
    }
  },
  {
    path: 'factures/create',
    component: InvoiceCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'addInvoices',
      mode: 'create'
    }
  },

  // Forecasted invoices.
  {
    path: 'factures-previsionnelles',
    component: ForecastedInvoiceListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'browseInvoices'
    }
  },
  {
    path: 'factures-previsionnelles/create',
    component: ForecastedInvoiceCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'addInvoices',
      mode: 'create'
    }
  },
  {
    path: 'factures-previsionnelles/:id/edit',
    component: ForecastedInvoiceCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'editInvoices',
      mode: 'edit'
    }
  },

  // Payments.
  {
    path: 'paiements',
    component: PaymentListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'browsePayments'
    }
  },

  // Analytics
  {
    path: 'analytics/turnover',
    component: TurnoverComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'browseAnalytics'
    }
  },
  {
    path: 'analytics/production-analysis',
    component: ProductionAnalysisComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'browseAnalytics'
    }
  },

  // * 404
  {
    path: '404',
    component: Error404Component,
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: '/404'
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
