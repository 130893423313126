import { Component, OnInit } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'

import { AbcCreateEditComponent } from '../../../common/base-components/abc-create-edit/abc-create-edit.component'
import { InputType } from '../../../common/enums/input-type.enum'
import { FieldSpecialRule } from '../../../common/interfaces/field-special-rule.interface'
import { Field } from '../../../common/interfaces/field.interface'
import { ResourceDefinition } from '../../../common/interfaces/resource-definition.interface'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { FlashMessageService } from '../../../common/services/flash-message.service'
import { ResourceService } from '../../../common/services/resource.service'
import { Customer } from '../../customer/customer.interface'
import { Nature } from '../../nature/nature.interface'
import { Referent } from '../../referent/referent.interface'
import { User } from '../../user/user.interface'
import { ProjectStatus } from '../project-status.enum'
import { projectDefinition } from '../project.definition'

@Component({
  templateUrl:
    '../../../common/base-components/abc-create-edit/abc-create-edit.component.html',
  styleUrls: [
    '../../../common/base-components/abc-create-edit/abc-create-edit.component.scss'
  ]
})
export class ProjectCreateEditComponent
  extends AbcCreateEditComponent
  implements OnInit {
  definition: ResourceDefinition = projectDefinition

  fields: Field[] = [
    {
      label: `Nom du projet`,
      placeholder: `Nom du projet...`,
      properties: {
        value: 'name'
      },
      inputType: InputType.Text,
      validators: [Validators.required],
      className: 'is-12-mobile is-3-tablet p-x-0-mobile'
    },
    {
      label: 'Client',
      placeholder: 'Chercher un client...',
      properties: {
        customerId: 'customerId'
      },
      retrievedItemProperties: {
        customerId: 'customer.id'
      },
      searchResources: ['customers'],
      maxSelectedItems: 1,
      validators: [Validators.required],
      inputType: InputType.MultiSearch,
      className: 'is-6 p-x-0-mobile',
      onChange: async (newValue: { customerId: number }) => {
        const referentIdField: Field = this.getFieldById('referentId')

        if (newValue.customerId) {
          const customer: Customer = await this.customResourceService
            .show('customers', newValue.customerId)
            .toPromise()
            .then((res) => res)

          referentIdField.selectOptions = customer.referents.map((r) => ({
            label: r.name,
            value: r.id.toString()
          }))
        } else {
          referentIdField.selectOptions = []
        }
      }
    },
    {
      label: `Date de création`,
      placeholder: `Date de création...`,
      properties: {
        value: 'creationDate'
      },
      inputType: InputType.Datepicker,
      validators: [Validators.required],
      className: 'is-3 p-x-0-mobile'
    },
    {
      label: 'Statut',
      properties: {
        value: 'status'
      },
      placeholder: 'Choisir un statut',
      inputType: InputType.Select,
      selectOptions: Object.keys(ProjectStatus).map((key) => ({
        label: ProjectStatus[key],
        value: ProjectStatus[key]
      })),
      validators: [Validators.required],
      className: 'is-3 p-x-0-mobile'
    },
    {
      label: `Ref bon de commande`,
      placeholder: `Ref bon de commande...`,
      properties: {
        value: 'purchaseOrder'
      },
      inputType: InputType.Text,
      validators: [],
      className: 'is-3 p-x-0-mobile'
    },

    {
      label: `Date de démarrage`,
      placeholder: `Date de démarrage...`,
      properties: {
        value: 'startDate'
      },
      inputType: InputType.Datepicker,
      validators: [],
      className: 'is-3 p-x-0-mobile'
    },

    {
      label: `Date de fin`,
      placeholder: `Date de fin...`,
      properties: {
        value: 'endDate'
      },
      inputType: InputType.Datepicker,
      validators: [],
      className: 'is-3 p-x-0-mobile'
    },
    {
      label: 'Nature de la mission',
      placeholder: 'Sélectionnez une nature',
      properties: {
        value: 'natureId'
      },
      retrievedItemProperties: {
        natureId: 'nature.id'
      },
      inputType: InputType.Select,
      validators: [Validators.required],
      selectOptions: () =>
        this.customResourceService
          .list('natures', { withoutPagination: true })
          .toPromise()
          .then((natureRes: Nature[]) =>
            natureRes.map((n) => ({
              label: n.name,
              value: n.id.toString()
            }))
          ),
      className: 'is-2 p-x-0-mobile'
    },
    {
      id: 'referentId',
      label: 'Responsable mission',
      placeholder: 'Sélectionnez un responsable (côté client)',
      properties: {
        value: 'referentId'
      },
      retrievedItemProperties: {
        referentId: 'referent.id'
      },
      inputType: InputType.Select,
      validators: [Validators.required],
      selectOptions: () =>
        this.customResourceService
          .list('referents', { withoutPagination: true })
          .toPromise()
          .then((referentRes: Referent[]) =>
            referentRes.map((r) => ({
              label: r.name,
              value: r.id.toString()
            }))
          ),
      className: 'is-5 p-x-0-mobile'
    },
    {
      label: `Chef de projet`,
      placeholder: `Choisir le chef de projet`,
      properties: {
        value: 'projectManagerId'
      },
      retrievedItemProperties: {
        projectManagerId: 'projectManager.id'
      },
      inputType: InputType.Select,
      selectOptions: () =>
        this.customResourceService
          .list('users', { withoutPagination: true })
          .toPromise()
          .then((userRes: User[]) =>
            userRes.map((u) => ({
              label: u.name,
              value: u.id.toString()
            }))
          ),
      className: 'is-5 p-x-0-mobile',
      validators: [Validators.required]
    },
    {
      label: 'Commentaires',
      placeholder: 'Commentaires...',
      properties: {
        value: 'comments'
      },
      inputType: InputType.Textarea,
      validators: [],
      className: 'is-12 p-x-0-mobile'
    }
  ]

  editModeSpecialRules: FieldSpecialRule[] = [
    {
      fieldId: 'isAbandoned',
      hidden: false
    }
  ]

  constructor(
    formBuilder: FormBuilder,
    router: Router,
    breadcrumbService: BreadcrumbService,
    resourceService: ResourceService,
    flashMessageService: FlashMessageService,
    activatedRoute: ActivatedRoute,
    private customResourceService: ResourceService
  ) {
    super(
      formBuilder,
      router,
      breadcrumbService,
      resourceService,
      flashMessageService,
      activatedRoute
    )
  }

  ngOnInit() {
    this.initCreateEditView()
  }
}
