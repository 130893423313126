import { Gender } from '../../common/enums/gender.enum'
import { LinkType } from '../../common/enums/link-type.enum'
import { YieldType } from '../../common/enums/yield-type.enum'
import { ResourceDefinition } from '../../common/interfaces/resource-definition.interface'
import { Invoice } from './invoice.interface'

export const invoiceDefinition: ResourceDefinition = {
  title: 'Factures',
  nameSingular: 'facture',
  namePlural: 'fatures',
  gender: Gender.Feminine,
  slug: 'invoices',
  path: 'factures',
  hasDetailPage: false,
  hasListPage: true,
  buttons: [LinkType.CREATE],
  defaultLink: null,
  childrenThatPreventDelete: [
    {
      propName: 'payments',
      preventDeleteMessage: `Certains paiements ont déjà été effectués pour cette facture`
    }
  ],
  actionButtons: [
    {
      label: '💸',
      className: 'button is-info p-x-lg',
      permission: 'validateInvoices',
      condition: (i: Invoice) => i.paymentPercentage < 1,
      linkAction: (i: Invoice) => ({
        path: `/projets/${i.project.id}`,
        queryParams: {
          selectedTab: 'accounting',
          invoiceToPay: i.id,
          reload: new Date().toISOString()
        }
      })
    }
  ],
  dropdownLinks: [
    {
      type: LinkType.DELETE,
      permission: 'deleteInvoices'
    },
    {
      type: LinkType.CUSTOM,
      permission: 'deleteInvoices',
      label: 'Supprimer un paiement',
      condition: (i: Invoice) => !!i.paymentDates,
      linkAction: (i: Invoice) => ({
        path: `projets/${i.project.id}`,
        queryParams: {
          selectedTab: 'accounting',
          invoiceToPay: i.id,
          reload: new Date().toISOString()
        }
      })
    }
  ],
  yields: [
    {
      label: 'Numéro',
      property: 'number'
    },
    {
      label: 'Libellé',
      property: 'reference'
    },

    {
      label: 'Récurrence',
      property: 'isRecurrent',
      type: YieldType.Icon,
      disableOrderBy: true
    },
    {
      label: 'Projet',
      property: 'project.name'
    },
    {
      label: 'Client',
      property: 'project.customer.name',
      orderByProperty: 'customer.name'
    },
    {
      label: `Date d'émission`,
      property: 'issueDate',
      type: YieldType.Date
    },
    {
      label: `Date d'échéance`,
      property: 'dueDate',
      type: YieldType.Date
    },
    {
      label: 'Montant HT',
      property: 'amount',
      type: YieldType.Currency
    },
    {
      label: 'Montant TTC',
      property: 'amountWithTaxes',
      type: YieldType.Currency
    },
    {
      label: 'Reglement',
      property: 'paymentPercentage',
      type: YieldType.AnalogProgressBar,
      disableOrderBy: true
    },
    {
      label: '',
      property: 'paymentDates'
    },
    {
      label: '',
      property: 'path',
      type: YieldType.Download
    }
  ],
  keyNumbers: [
    {
      label: 'Total facturé (HT)',
      className: 'label',
      extraParams: {
        calculateTotal: '1'
      }
    }
  ]
}
