import { Component, Input } from '@angular/core'
import * as moment from 'moment'

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent {
  @Input() path: string
  @Input() isCollapsed = false

  activeAccordion: string

  currentMonthParams = {
    dateFrom: moment().startOf('month').format('YYYY-MM-DD'),
    dateTo: moment().endOf('month').format('YYYY-MM-DD')
  }

  defaultMonitoringParams = {
    year: moment().format('YYYY'),
    month: moment().format('M')
  }

  toggleAccordion(accordion: string): void {
    if (this.activeAccordion === accordion) {
      this.activeAccordion = null
    } else {
      this.activeAccordion = accordion
    }
  }

  hideCollapsedAccordion() {
    if (this.isCollapsed) {
      delete this.activeAccordion
    }
  }

  showCollapsedAccordion(accordion: string): void {
    if (this.isCollapsed) {
      this.activeAccordion = accordion
    }
  }

  openSelectedAccordion() {
    // Open correct dropdown based on current path.
    if (this.path.includes('/clients') || this.path.includes('/referents')) {
      this.toggleAccordion('customer')
    } else if (
      this.path.includes('/factures') ||
      this.path.includes('/paiements')
    ) {
      this.toggleAccordion('invoice')
    } else if (
      this.path.includes('/collaborateurs') ||
      this.path.includes('/time-sheets')
    ) {
      this.toggleAccordion('user')
    } else if (this.path.includes('/analytics')) {
      this.toggleAccordion('analytics')
    } else {
      this.toggleAccordion('setting')
    }
  }
}
