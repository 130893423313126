import { Component, OnInit } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'

import { AbcCreateEditComponent } from '../../../common/base-components/abc-create-edit/abc-create-edit.component'
import { InputType } from '../../../common/enums/input-type.enum'
import { AverageRate } from '../../../common/interfaces/average-rate.interface'
import { Field } from '../../../common/interfaces/field.interface'
import { ResourceDefinition } from '../../../common/interfaces/resource-definition.interface'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { FlashMessageService } from '../../../common/services/flash-message.service'
import { ResourceService } from '../../../common/services/resource.service'
import { natureDefinition } from '../../nature/nature.definition'
import { Position } from '../../position/position.interface'

@Component({
  templateUrl: './nature-create-edit.component.html',
  styleUrls: [
    '../../../common/base-components/abc-create-edit/abc-create-edit.component.scss'
  ]
})
export class NatureCreateEditComponent
  extends AbcCreateEditComponent
  implements OnInit {
  definition: ResourceDefinition = natureDefinition

  positions: Position[]

  fields: Field[] = [
    {
      id: 'nameField',
      label: 'Nom de la nature de chantier',
      properties: {
        value: 'name'
      },
      inputType: InputType.Text,
      validators: [Validators.required],
      className: 'p-x-0-mobile is-6'
    },
    {
      label: 'Couleur',
      properties: { value: 'color' },
      className: 'p-x-0-mobile is-3',
      inputType: InputType.ColorPicker,
      validators: [Validators.required]
    }
  ]

  constructor(
    formBuilder: FormBuilder,
    router: Router,
    breadcrumbService: BreadcrumbService,
    resourceService: ResourceService,
    flashMessageService: FlashMessageService,
    activatedRoute: ActivatedRoute,
    private customResourceService: ResourceService,
    private customActivatedRoute: ActivatedRoute
  ) {
    super(
      formBuilder,
      router,
      breadcrumbService,
      resourceService,
      flashMessageService,
      activatedRoute
    )
  }

  async ngOnInit() {
    this.positions = await this.customResourceService
      .list('positions', { withoutPagination: true })
      .toPromise()
      .then((res) => res)

    // Create a field for each Position's AverageRate. If no averageRate, we set the value to 0.
    this.positions.forEach((position: Position) => {
      let averageRateForCurrentItem: AverageRate
      if (this.customActivatedRoute.snapshot.data.mode === 'edit') {
        averageRateForCurrentItem = position.averageRates.find(
          (aR: AverageRate) =>
            aR.nature &&
            aR.nature.id ===
              parseInt(this.customActivatedRoute.snapshot.params.id)
        )
      }

      this.fields.push({
        label: `Tarif journalier moyen : ${position.name}`,
        forcedValue: {
          value: averageRateForCurrentItem
            ? averageRateForCurrentItem.dailyRate
            : 0
        },
        properties: { value: `averageRateForPositionId${position.id}` },
        className: 'is-12-mobile is-4-tablet is-3-widescreen',
        inputType: InputType.Number,
        validators: [Validators.required]
      })
    })

    await this.initCreateEditView()
  }

  // Custom submit that adds averageRateForPositions prop as an array.
  async submitNature() {
    this.form.value.averageRates = []

    Object.keys(this.form.value).forEach((key) => {
      if (key.substr(0, 24) === 'averageRateForPositionId') {
        this.form.value.averageRates.push({
          positionId: parseInt(key.substr(24), 10),
          dailyRate: this.form.value[key]
        })
        delete this.form.value[key]
      }
    })

    this.submit()
  }
}
