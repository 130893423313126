import { Component, OnInit } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'

import { AbcCreateEditComponent } from '../../../common/base-components/abc-create-edit/abc-create-edit.component'
import { InputType } from '../../../common/enums/input-type.enum'
import { Field } from '../../../common/interfaces/field.interface'
import { ResourceDefinition } from '../../../common/interfaces/resource-definition.interface'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { FlashMessageService } from '../../../common/services/flash-message.service'
import { ResourceService } from '../../../common/services/resource.service'
import { User } from '../../user/user.interface'
import { customerDefinition } from '../customer.definition'

@Component({
  templateUrl:
    '../../../common/base-components/abc-create-edit/abc-create-edit.component.html',
  styleUrls: [
    '../../../common/base-components/abc-create-edit/abc-create-edit.component.scss'
  ]
})
export class CustomerCreateEditComponent
  extends AbcCreateEditComponent
  implements OnInit {
  definition: ResourceDefinition = customerDefinition

  fields: Field[] = [
    {
      label: 'Nom du client',
      placeholder: `Entrez le nom d'un client`,
      properties: {
        value: 'name'
      },
      inputType: InputType.Text,
      validators: [Validators.required],
      className: 'is-4 p-x-0-mobile'
    },
    {
      label: 'Email de facturation',
      placeholder: 'Email de facturation',
      properties: {
        value: 'billingEmail'
      },
      inputType: InputType.Email,
      validators: [Validators.required],
      className: 'is-4 p-x-0-mobile'
    },
    {
      label: 'Responsable client',
      properties: {
        value: 'accountManagerId'
      },
      retrievedItemProperties: {
        accountManagerId: 'accountManager.id'
      },
      placeholder: 'Responsable client...',
      selectOptions: () =>
        this.customResourceService
          .list('users', { withoutPagination: true, positionName: 'Partner' })
          .toPromise()
          .then((userRes: User[]) =>
            userRes.map((u) => ({
              label: u.name,
              value: u.id.toString()
            }))
          ),
      inputType: InputType.Select,
      className: 'is-4 p-x-0-mobile',
      validators: [Validators.required]
    },
    {
      label: 'Adresse',
      placeholder: 'Adresse du client',
      properties: {
        value: 'address'
      },
      inputType: InputType.GooglePlaces,
      validators: [Validators.required],
      className: 'is-4 p-x-0-mobile'
    },
    {
      label: 'Adresse de facturation',
      placeholder: `Adresse de facturation`,
      properties: {
        value: 'billingAddress'
      },
      inputType: InputType.Textarea,
      validators: [Validators.required],
      className: 'is-4 p-x-0-mobile'
    },
    {
      label: 'Délai de paiement (en jours)',
      placeholder: 'Nombre de jours de délai de paiement...',
      properties: {
        value: 'paymentPeriod'
      },
      validators: [Validators.required],
      inputType: InputType.Number,
      className: 'is-4 p-x-0-mobile'
    },
    {
      label: `N° de fournisseur chez le client`,
      placeholder: `N° de fournisseur chez le client`,
      properties: {
        value: 'supplierNumber'
      },
      inputType: InputType.Text,
      validators: [],
      className: 'is-4 p-x-0-mobile'
    },
    {
      label: `N° de TVA intracommunautaire`,
      placeholder: `N° de TVA intracommunautaire`,
      properties: {
        value: 'VATNumber'
      },
      inputType: InputType.Text,
      validators: [],
      className: 'is-4 p-x-0-mobile'
    },
    {
      label: `N° de contrat cadre`,
      placeholder: `N° de contrat cadre`,
      properties: {
        value: 'contractNumber'
      },
      inputType: InputType.Text,
      validators: [],
      className: 'is-4 p-x-0-mobile'
    },
    {
      label: `Logo`,
      placeholder: `Logo`,
      properties: {
        value: 'logo'
      },
      inputType: InputType.Image,
      validators: [],
      className: 'is-4 p-x-0-mobile'
    }
  ]

  constructor(
    formBuilder: FormBuilder,
    router: Router,
    breadcrumbService: BreadcrumbService,
    resourceService: ResourceService,
    flashMessageService: FlashMessageService,
    activatedRoute: ActivatedRoute,
    private customResourceService: ResourceService
  ) {
    super(
      formBuilder,
      router,
      breadcrumbService,
      resourceService,
      flashMessageService,
      activatedRoute
    )
  }

  ngOnInit() {
    this.initCreateEditView()
  }
}
